/**
 * Class which contains a useful list of constants which appear within journal client
 */
export class JournalConstants {
  // Defines mappings for environment codes to environment. These are returned by clusterloader.getEnvironments on the backend, which is just reading a hard-coded json file.
  static readonly LOCAL: string = 'local';
  static readonly LOADTEST: string = 'loadtest';
  static readonly PROD_ENVS: string[] = ['production (ACHM)', 'production (APDX)', 'production (AFRA)', 'production (AORE)', 'production (ANRT)', 'production (alpha-apdx)'];
  static readonly INT_ENVS: string[] = ['integration (INTB1)', 'integration (INTB2)'];
  static readonly FED_ENVS: string[] = ['FedRAMP'];

  // Defines column names (TODO: all hardcoded strings should be brought into this file,
  // and used in combination with utilites/data-extraction/findColumnIndexFromName if necessary)
  static readonly COLUMN_TIMESTAMP: string = 'Timestamp';
  static readonly COLUMN_JUMP = "Jump To Journal";
  static readonly COLUMN_DEVICE_EVENT = 'Device Event';

  static readonly EVENT_REPLACED_BY = "REPLACED_BY";
  static readonly EVENT_REPLACES = "REPLACES";

  // Defines backend endpoint names
  static readonly LOCUS_STATIC_API_ENDPOINT = 'locusStatic';
  static readonly MEETING_CLT_ENDPOINT = "meetingClt";
  static readonly WALK_JOURNAL_API_ENDPOINT = 'walkJournal';
  static readonly ABOUT_INFO_ENDPOINT = 'aboutJournalInfo';
  static readonly MEETING_INFO_API_ENDPOINT = 'loci/meetingInfo';
  static readonly JOURNAL_ROWS_API_ENDPOINT = 'loci/getJournalRows';
  static readonly JOURNAL_ROWS_ROLL_UP_API_ENDPOINT = 'loci/getJournalRows';
  static readonly CALL_SUMMARY_API_ENDPOINT = 'loci/callSummary';
  static readonly START_TIMES_ENDPOINT = 'loci/getArchivedStartTimes';
  static readonly SESSION_INFO_API_ENDPOINT = 'loci/getArchivedSessionInfo';
  static readonly CALL_FLOW_ENDPOINT = 'loci/getCallFlowDiagram';

  // Defines error messages from backend requests
  static readonly ABOUT_INFO_ERROR_MSG = 'Unable to get About Info';

  // Defines constants used in backend request service tests
  static readonly TARGET_ENV_PROD = 'prod';
  static readonly TARGET_ENV_LOCAL = 'local';

  // matching names in the ../../styles/<theme>.scss files
  static JT_LIGHT_THEME = 'jt-light-theme';
  static JT_DARK_THEME = 'jt-dark-theme';
  static AG_LIGHT_THEME = 'ag-theme-balham';
  static AG_DARK_THEME = 'ag-theme-balham-dark';
}
