<div class="flex-container">
  <div class="flex-container-row">
    <button mat-raised-button color="primary" aria-label="toggle view mode"
            (click)="toggleView('textView', 'jsonViewer')">Toggle View</button>
    <button mat-raised-button color="primary" aria-label="expand all" [disabled]="!jsonView"
            (click)="toggleExpand()">Expand</button>
  </div>
  <div class="flex-container-row">
    <button mat-raised-button aria-label="participant"
            *ngFor="let participant of this.participantList;"
            [color]="participant.index === currentParticipant ? 'accent' : 'primary'"
            (click)="showParticipantDTO(participant.index,participant.name)">{{participant.name}}</button>
  </div>
  <div id="json-container">
    <ngx-json-viewer id="jsonViewer" [json]="currentRawData" [depth]="this.expandAll"></ngx-json-viewer>
  </div>
  <pre id="textView" style="display: none;">{{ currentRawData | json }}</pre>
  <app-jstree-spinner></app-jstree-spinner>
</div>
