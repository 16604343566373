import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JournalToolBackendRequestsService } from '../_services/journal-tool-backend-requests.service';
import { MatTable } from '@angular/material/table';

export interface ProjectDependency {
  dependency: string;
  version: string;
}

@Component({
  selector: 'app-about',
  templateUrl: './about-info.component.html',
  styleUrls: ['./about-info.component.scss']
})
export class AboutInfoComponent implements OnInit {
  journalToolDependencies: ProjectDependency[] = [];

  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    private route: ActivatedRoute,
    private backendService: JournalToolBackendRequestsService
  ) {}

  async ngOnInit() {
    let response = await this.backendService.getAboutInfo(this.route.snapshot.queryParams['environment']);

    if (response['status'] === this.backendService.SUCCESS) {
      let data: { key: string, value: string } = response['data'];
      for (const [dependency, version] of Object.entries(data).sort(
        (a, b) => a[0].toLocaleLowerCase().localeCompare(b[0].toLocaleLowerCase()))) {
        this.journalToolDependencies.push({dependency: dependency, version: version});
      };

      this.table.renderRows();
    } else if (response['status'] === this.backendService.FAILURE) {
      console.error("Failed to load about info from backend");
    }
  }
}
