import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {JournalToolBackendRequestsService} from '../_services/journal-tool-backend-requests.service';
import {JstreeSpinnerComponent} from '../jstree-spinner/jstree-spinner.component';
import {UiUtils} from '../_utilities/ui-utils';

@Component({
  selector: 'app-show-attrs',
  templateUrl: './show-attrs.component.html',
  styleUrls: ['./show-attrs.component.scss']
})
export class ShowAttrsComponent implements OnInit {

  data = {};
  walkJournalData = null;
  jsonView = true;

  constructor(private http: HttpClient, private route: ActivatedRoute,
              private backendService: JournalToolBackendRequestsService,
              private jstreeSpinner: JstreeSpinnerComponent) {
  }

  async ngOnInit() {

    document.title = `Attrs, row ${this.route.snapshot.queryParams['rowIndex']} : ${this.route.snapshot.queryParams['locusID']} @ ${this.route.snapshot.queryParams['date']}`;

    UiUtils.setWaitCursor();

    if (!window['data']) {
      let request = await this.backendService.getJournal(
        this.route.snapshot.queryParams['environment'],
        this.route.snapshot.queryParams['locusID'],
        this.route.snapshot.queryParams['date']);

      if (request['status'] === this.backendService.FAILURE) {
        console.log(request['errorMessage']);
      } else {
        this.walkJournalData = request['data'];
      }
    } else {
      this.walkJournalData = window['data'];
    }

    if (this.walkJournalData !== null) {
      this.data = this.walkJournalData['response']['rowList'][this.route.snapshot.queryParams['rowIndex'] - 1];  // rowList is 0-indexed

      if (this.data['attrs']) {
        this.data = JSON.parse(this.data['attrs']);//For some reason attrs is stored as a string that contains all the json data;
        this.data['rowIndex'] = (this.route.snapshot.queryParams['rowIndex']) ? this.route.snapshot.queryParams['rowIndex'] : 'Unknown';
      }
    }

    this.jstreeSpinner.hide();
    UiUtils.setDefaultCursor();
  }

  public toggleView(textViewId, jsTreeId) {
    if (this.jsonView) {
      document.getElementById(textViewId).style.display = "block";
      document.getElementById(jsTreeId).style.display = "none";
      this.jsonView = false;
    } else {
      document.getElementById(textViewId).style.display = "none";
      document.getElementById(jsTreeId).style.display = "block";
      this.jsonView = true;
    }
  }

}
