import { DateTimeHelpers } from "../_utilities/date-utils";
import { JournalConstants } from "../_constants/journal-constants";

/**
 * Class which contains simple utility functions for extracting data from the
 * journal data tables (model)
 */
export class DataExtractionHelpers {

  // Defines if a locus id / meeting of interest is in production or integration
  static readonly MEETING_ENV_PROD: string = 'meet-prod';
  static readonly MEETING_ENV_INT: string = 'meet-int';
  static readonly MEETING_ENV_FED: string = 'meet-fed';
  static readonly MEETING_ENV_LOCAL: string = 'meet-dev';
  static readonly MEETING_ENV_LOADTEST: string = 'meet-loadtest';

  /**
   * Returns if the given locus id / meeting environment code is production, fedramp, local, loadtest, or integration
   *
   * requires - env != null, and for env to be a valid meeting environment code
   *
   * @param env - The meeting environment code
   * @returns MEETING_ENV_PROD, MEETING_ENV_INT, MEETING_ENV_FED, MEETING_ENV_LOCAL, MEETING_ENV_LOADTEST as appropriate for the given meeting environment code
   */
  static getMeetingEnvironment(env: string): string {
    if (env == null) {
      throw new ReferenceError(`env is null`);
    }

    if (JournalConstants.PROD_ENVS.find(envCode => env.includes(envCode))) {
      return DataExtractionHelpers.MEETING_ENV_PROD;
    } else if (env === JournalConstants.LOCAL) {
      return DataExtractionHelpers.MEETING_ENV_LOCAL;
    } else if (env === JournalConstants.LOADTEST) {
      return DataExtractionHelpers.MEETING_ENV_LOADTEST;
    } else if (JournalConstants.INT_ENVS.find(envCode => env.includes(envCode))) {
      return DataExtractionHelpers.MEETING_ENV_INT;
    } else if (JournalConstants.FED_ENVS.find(envCode => env.includes(envCode))) {
      return DataExtractionHelpers.MEETING_ENV_FED;
    }

    throw new ReferenceError(`env \'${env}\' is not a valid environment code`);
  }

  /**
   * Finds the index of the column in the given columnTable, or -1 if not found
   *
   * requries - columnTable, colName != null
   *
   * @param columnTable - A list of column names in the journal table
   * @param colName - The column whose index we want to return
   * @returns The index of the column whose name matches colName, or -1 if no matches are found
   */
  static findColumnIndexFromName(columnTable: string[], colName: string): number {
    if (columnTable == null) {
      throw new ReferenceError(`columnTable is null`);
    }

    if (colName == null) {
      throw new ReferenceError(`colName is null`);
    }

    return columnTable.findIndex((name) => name == colName);
  }

  /**
   * Retreives a date object without the local machines timezone offset for the timestamp of the transaction
   * the given row is a part of
   *
   * requires - The row to be within the range [0, displayTable.length - 1], displayTable, columnTable != null
   *
   * @param displayTable - A row ordered array of row data which is displayed to the user
   * @param columnTable - A table which lists the names of displayTables columns in order
   * @param row - The index into the array specifying the row whose transaction timestamp we want to search for
   * @returns The timestamp of the transaction the row is a part of
   */
  static getTransactionTimestamp(displayTable: any[], columnTable: string[], row: number): Date {
    if (displayTable == null) {
      throw new ReferenceError(`displayTable is null`);
    }

    if (columnTable == null) {
      throw new ReferenceError(`columnTable is null`);
    }

    if (row < 0 || row >= displayTable.length) {
      throw new RangeError(`row ${row} outside the allowed range [${0}, ${displayTable.length - 1}]`);
    }

    let startRow = DataExtractionHelpers.findTransactionBeginning(displayTable, columnTable, row);
    let timestampColumn = DataExtractionHelpers.findColumnIndexFromName(columnTable, JournalConstants.COLUMN_TIMESTAMP);
    return DateTimeHelpers.timestampToDate(displayTable[startRow][timestampColumn]);
  }

  /**
   * Computes the range of indicies which define the transaction that the row is a part of
   *
   * requires - The row to be within the range [0, displayTable.length - 1], displayTable, columnTable != null
   *
   * @param displayTable - A row ordered array of row data which is displayed to the user
   * @param columnTable - A table which lists the names of displayTables columns in order
   * @param row - The index into the array specifying the row whose transaction range we want to return
   * @returns The indices into the array which define the beginning and end of the transaction that
   */
  static getTransactionRange(displayTable: any[], columnTable: string[], row: number): [number, number] {
    if (displayTable == null) {
      throw new ReferenceError(`displayTable is null`);
    }

    if (columnTable == null) {
      throw new ReferenceError(`columnTable is null`);
    }

    if (row < 0 || row >= displayTable.length) {
      throw new RangeError(`row ${row} outside the allowed range [${0}, ${displayTable.length - 1}]`);
    }

    return [
      DataExtractionHelpers.findTransactionBeginning(displayTable, columnTable, row),
      DataExtractionHelpers.findTransactionEnding(displayTable, columnTable, row)
    ];
  }

  /**
   * Computes the index of the row that marks the beginning of the transaction that the given
   * row is a part of
   *
   * requires - The row to be within the range [0, displayTable.length - 1], displayTable, columnTable != null
   *
   * @param displayTable - A row ordered array of row data which is displayed to the user
   * @param columnTable - A table which lists the names of displayTables columns in order
   * @param row - The index into the array specifying the row whose transaction start we want to return
   * @returns The index of the row that begins the transaction the given row is a member of
   */
  static findTransactionBeginning(displayTable: any[], columnTable: string[], row: number): number {
    if (displayTable == null) {
      throw new ReferenceError(`displayTable is null`);
    }

    if (columnTable == null) {
      throw new ReferenceError(`columnTable is null`);
    }

    if (row < 0 || row >= displayTable.length) {
      throw new RangeError(`row ${row} outside the allowed range [${0}, ${displayTable.length - 1}]`);
    }

    let lowerLimit = row;
    let timestampColumn = DataExtractionHelpers.findColumnIndexFromName(columnTable, JournalConstants.COLUMN_TIMESTAMP);

    while (lowerLimit < displayTable.length && lowerLimit >= 0 && displayTable[lowerLimit][timestampColumn] === '') {
      lowerLimit--;
    }

    return lowerLimit;
  }

  /**
   * Computes the index of the row that marks the ending of the transaction that the given
   * row is a part of
   *
   * requires - The row to be within the range [0, displayTable.length - 1], displayTable, columnTable != null
   *
   * @param displayTable - A row ordered array of row data which is displayed to the user
   * @param columnTable - A table which lists the names of displayTables columns in order
   * @param row - The index into the array specifying the row whose transaction end we want to return
   * @returns - The index of the row that ends the transaction the given row is a member of
   */
  static findTransactionEnding(displayTable: any[], columnTable: string[], row: number): number {
    if (displayTable == null) {
      throw new ReferenceError(`displayTable is null`);
    }

    if (columnTable == null) {
      throw new ReferenceError(`columnTable is null`);
    }

    if (row < 0 || row >= displayTable.length) {
      throw new RangeError(`row ${row} outside the allowed range [${0}, ${displayTable.length - 1}]`);
    }

    let upperLimit = Math.min(row + 1, displayTable.length - 1);
    let timestampColumn = DataExtractionHelpers.findColumnIndexFromName(columnTable, JournalConstants.COLUMN_TIMESTAMP);

    while (upperLimit < displayTable.length && displayTable[upperLimit][timestampColumn] === '') {
      upperLimit++;
    }

    return upperLimit - 1;
  }

  /**
   * Searches a range of rows for the first instance of the given attribute
   *
   * requires - The start and end to be within the range [0, attributeTable.length - 1], attributeTable != null,
   * attribute != null and for startRow <= endRow
   *
   * @param attributeTable - A row ordered array of row data formatted as JSON strings
   * @param startingRow - The index into the array to begin the search
   * @param endingRow - The index into the array to stop the search
   * @param attribute - The name of the top-level attribute to search the given row for
   * @returns The value of the first instance of the attribute, or null if not found
   */
  static searchJSONRowRangeForAttribute(attributeTable: string[], startingRow: number, endingRow: number, attribute: string): any {
    if (attributeTable == null) {
      throw new ReferenceError(`attributeTable is null`);
    }

    if (attribute == null) {
      throw new ReferenceError(`attribute is null`);
    }

    if (startingRow < 0 || startingRow >= attributeTable.length) {
      throw new RangeError(`row ${startingRow} outside the allowed range [${0}, ${attributeTable.length - 1}]`);
    }

    if (endingRow < 0 || endingRow >= attributeTable.length) {
      throw new RangeError(`row ${endingRow} outside the allowed range [${0}, ${attributeTable.length - 1}]`);
    }

    if (endingRow < startingRow) {
      throw new RangeError(`row ${endingRow} comes before start row ${startingRow}`);
    }

    for (let i = startingRow; i <= endingRow ; i++) {
      let rowTable = JSON.parse(attributeTable[i]);

      // If the row has a key for the attribute, then return the desired value
      if (rowTable['attrs'] && rowTable['attrs'][attribute]) {
        return rowTable['attrs'][attribute];
      }
    }

    return null;
  }
}
