import { ITooltipComp, ITooltipParams } from 'ag-grid-community';

export class CustomTooltip implements ITooltipComp {
  eGui: any;
  init(params: ITooltipParams & { color: string }) {
    const eGui = (this.eGui = document.createElement('div'));
    const data = params.api!.getDisplayedRowAtIndex(params.rowIndex!)!.data;
    const size = params.data

    eGui.classList.add('custom-tooltip');
    //@ts-ignore
    eGui.innerHTML = `

            <p>
                <span>Use the Jump button to jump
                to Journal:
                <br>Associated journal will open auto-scrolled
                <br>based on this row's transaction time stamp </span>
            </p>

        `;
  }

  getGui() {
    return this.eGui;
  }
}
