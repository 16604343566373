// Class which contains simple utility functions for transforming strings to dates
// and vice versa
export class DateTimeHelpers {
    // Creates a Date object representing the given moment in time
    // timestamp - a string representing a moment in time
    // returns - a date object for the given timestamp
    static timestampToDate(timestamp: string): Date {
        // The Date object will internally represent any timestamp given to it in UTC time.
        // However the behavior of the getters seems to depend on the format of the string
        // in the constructor (whatever timezone the input is in).
        // If the timestamp is an ISO string, then the date does not need to be corrected (ie,
        // calling .toISOString() will return an iso string without adjusting for the timezone
        // the machine is in).
        // If the timestamp is not, for example if the 'Z' is removed from the end of the ISO
        // string before being sent in, then calling .toISOString() will produce an ISO timestamp
        // that is localized to where the machine is currently running.
        return new Date(timestamp);
    }
}