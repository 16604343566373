<div class="flex-container">
  <div class="flex-container-row">
    <button mat-raised-button color="primary" aria-label="toggle view mode"
            (click)="toggleView('textView', 'jsonViewer')">Toggle View</button>
    <button mat-raised-button color="primary" aria-label="expand all" [disabled]="!jsonView"
            (click)="toggleExpand()">Toggle Expand</button>
  </div>
  <div id="json-container">
    <ngx-json-viewer id="jsonViewer" [json]="data" [expanded]="expandAll"></ngx-json-viewer>
  </div>
  <pre id="textView" style="display: none;">{{ data | json }}</pre>
  <app-jstree-spinner></app-jstree-spinner>
</div>
