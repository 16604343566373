import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER} from '@angular/core';
import {MaterialModule} from './material/material.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthenticationService} from './_services/authentication/authentication.service';
import {AuthInfoService} from './_services/authInfo.service';
import {ThemeService} from "./_services/theme.service";

import {AuthInterceptor} from './_interceptors/auth.interceptor';
import {ResponseInterceptor} from './_interceptors/response-interceptor';
import {NavComponent} from './nav/nav.component';

import {HeaderComponent} from './header/header.component';
import {OverviewComponent} from './overview/overview.component';
import {FeedbackComponent} from './feedback/feedback.component';
import {LocusStaticComponent} from './locus-static-component/locus-static-component.component';
import {CallSummaryComponent} from './call-summary/call-summary.component';
import {JournalRowsComponent} from './journal-rows/journal-rows.component';
import {LocusMeetingInfoComponent} from './locus-meeting-info/locus-meeting-info.component';
import {WalkJournalComponent} from './walk-journal/walk-journal.component';
import {AgGridModule} from 'ag-grid-angular';
import {ShowLegendComponent} from './show-legend/show-legend.component';
import {ShowAttrsComponent} from './show-attrs/show-attrs.component';
import {GetDTOsComponent} from './get-dtos/get-dtos.component';
import {DialogBoxComponent} from './dialog-box/dialog-box.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MeetingCltComponent} from "./meeting-clt/meeting-clt.component";
import {HashGeneratorComponent} from './toolbar/hash-generator/hash-generator.component';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {AboutInfoComponent} from './about-info/about-info.component';
import {MenuButtonComponent} from './menu-button/menu-button.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BreakoutSummaryComponent} from './breakout-summary/breakout-summary.component';
import {JournalJumpRendererComponent} from "./journal-jump-button/journal-jump-button.component";
import {AutoSelectDirective} from "./_directives/autoselect";
import {JstreeSpinnerComponent} from './jstree-spinner/jstree-spinner.component';
import {ShowCountPlotComponent} from "./show-count-plot/show-count-plot.component";
import * as $ from 'jquery';
import {NgxJsonViewerModule} from "ngx-json-viewer";
import {envConfigAppInitializerFactory, EnvironmentService} from "./_services/environment.service";

@NgModule({
  declarations: [
    AppComponent,
    AutoSelectDirective,
    LoginComponent,
    NavComponent,
    ToolbarComponent,
    HeaderComponent,
    OverviewComponent,
    FeedbackComponent,
    LocusStaticComponent,
    MeetingCltComponent,
    CallSummaryComponent,
    JournalRowsComponent,
    LocusMeetingInfoComponent,
    WalkJournalComponent,
    ShowLegendComponent,
    ShowAttrsComponent,
    GetDTOsComponent,
    DialogBoxComponent,
    HashGeneratorComponent,
    MenuButtonComponent,
    BreakoutSummaryComponent,
    AboutInfoComponent,
    JournalJumpRendererComponent,
    JstreeSpinnerComponent,
    ShowCountPlotComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    AgGridModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatTooltipModule,
    NgxJsonViewerModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: envConfigAppInitializerFactory,
      deps: [EnvironmentService],
      multi: true,
    },
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    AuthInfoService,
    ThemeService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
