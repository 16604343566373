import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {EnvironmentService} from '../_services/environment.service';
import {JournalToolBackendRequestsService} from '../_services/journal-tool-backend-requests.service';
import {JstreeSpinnerComponent} from '../jstree-spinner/jstree-spinner.component';
import {UiUtils} from '../_utilities/ui-utils';

@Component({
  selector: 'app-get-dtos',
  templateUrl: './get-dtos.component.html',
  styleUrls: ['./get-dtos.component.scss']
})
export class GetDTOsComponent implements OnInit {

  data = {};
  walkJournalData = null;
  participantList = [];
  currentParticipant = -1;
  currentRawData = {};
  jsonView = true;
  expandAll = 0;

  constructor(private http: HttpClient, private route: ActivatedRoute,
              private environment: EnvironmentService,
              private backendService: JournalToolBackendRequestsService,
              private jstreeSpinner: JstreeSpinnerComponent) {
  }

  async ngOnInit() {

    document.title = `DTOs, row ${this.route.snapshot.queryParams['rowIndex']} : ${this.route.snapshot.queryParams['locusID']} @ ${this.route.snapshot.queryParams['date']}`;

    UiUtils.setWaitCursor();

    if (!window['data']) {
      let request = await this.backendService.getJournal(
        this.route.snapshot.queryParams['environment'],
        this.route.snapshot.queryParams['locusID'],
        this.route.snapshot.queryParams['date']);

      if (request['status'] === this.backendService.FAILURE) {
        console.log(request['errorMessage']);
      } else {
        this.walkJournalData = request['data'];
      }
    } else {
      this.walkJournalData = window['data'];
    }

    if (this.walkJournalData !== null) {
      let baseUrl: string;
      if (this.route.snapshot.queryParams['environment'] === 'local') {
        baseUrl = this.environment.getDevJournalServiceUrl();
      } else {
        baseUrl = this.environment.getJournalServiceUrl();
      }
      const urlToQuery = baseUrl + '/api/v1/loci/getDTOs/' + this.route.snapshot.queryParams['locusID'];
      // participantObject is used to modify the data['locus']['participants'] .
      // initially data['locus']['participants'] has structure "participantIndex: participant". To make it "participantName: participant",
      // we will use participantObject.
      let participantObject = {};
      let mediaShareObject = {};
      this.http.get(
        urlToQuery,
        {
          params: {
            'environment': this.route.snapshot.queryParams['environment'],
            'date': this.route.snapshot.queryParams['date'],
            'rowIndex': this.route.snapshot.queryParams['rowIndex']
          }
        })
        .pipe(map(data => {
          return data;
        })).subscribe(data => {
        for (let i = 0; i < data['locus']['mediaShares'].length; i++) {
          let mediaShareIndex = data['locus']['mediaShares'][i];
          let mediaShareName = mediaShareIndex['name'];
          mediaShareObject[mediaShareName] = mediaShareIndex;
        }
        data['locus']['mediaShares'] = mediaShareObject;
        for (let i = 0; i < data['locus']['participants'].length; i++) {
          let participantIndex = data['locus']['participants'][i];
          let participantCIName = this.walkJournalData['userMap'][participantIndex['id']];
          participantIndex['person']['internalCIName'] = participantCIName;
          participantObject[participantCIName] = participantIndex;
          this.participantList.push({name: participantIndex['person']['internalCIName'], index: i});
        }
        data['locus']['participants'] = participantObject;
        this.participantList.sort((a, b) => (a.name > b.name ? 1 : (a.name < b.name ? -1 : 0)));
        this.participantList.unshift({name: 'locus', index: -1});
        this.data = data;
        this.currentRawData = data;

        this.jstreeSpinner.hide();
        UiUtils.setDefaultCursor();
        return data;
      }, null, () => {
      });
    } else {
      this.jstreeSpinner.hide();
      UiUtils.setDefaultCursor();
    }
  }

  public toggleView(textViewId, jsTreeId) {
    if (this.jsonView) {
      document.getElementById(textViewId).style.display = "block";
      document.getElementById(jsTreeId).style.display = "none";
      this.jsonView = false;
    } else {
      document.getElementById(textViewId).style.display = "none";
      document.getElementById(jsTreeId).style.display = "block";
      this.jsonView = true;
    }
  }

  public toggleExpand() {
    this.expandAll++;
  }

  // participantIndex is used to get the individual data. By default, whole locus data is shown
  public showParticipantDTO(participantIndex, participantName) {
    this.currentParticipant = participantIndex;
    let nodeName;
    if (participantIndex < 0) {
      nodeName = 'locus';
      this.currentRawData = this.data;
    } else {
      nodeName = participantName;
      this.currentRawData = this.data['selves'][participantIndex];
    }
  }

}


