<div class="flex-container">
  <div class="flex-container-row">
  <span class="hash-label hashGenerator">SHA512</span>
  <div class="left-right-padding-inline">
    <input id="sha-input" type="text" [(ngModel)]="genFrom" class="hash-text-input hashGenerator" placeholder="Enter value to hash, &#9166; to generate"
           (keydown)="checkKey($event)" (input)="isClear()"/>
  </div>
  <span class="hash-text-output hashGenerator" type="text">{{sha512}}</span>
  </div>
</div>
