/**
 * Class which contains a list of constants related to Kibana
 */
export class KibanaConstants {
  // Define constants used to manipulate timestamps (in minutes)
  static readonly PAST_OFFSET: number = 5;
  static readonly FUTURE_OFFSET: number = 5;

  // Define the options which can be selected in the drop down menu
  static readonly OPEN_GENERAL_MEETING: string = 'Find Meeting';
  static readonly OPEN_ROW_SELECTIONS: string = 'Find Selected Rows';
  static readonly MENU_OPTIONS: string[] = [
    KibanaConstants.OPEN_GENERAL_MEETING,
    KibanaConstants.OPEN_ROW_SELECTIONS
  ];

  // Define the Kibana host URL segments. Note that FedRamp requires a more complex link
  static readonly PRODUCTION_LOGS: string = 'logs.o.webex.com';
  static readonly PRODUCTION_INDEX: string = 'index:wxm-app_wxm-app-eu1';
  static readonly INTEGRATION_LOGS: string = 'logs.o-int.webex.com';
  static readonly INTEGRATION_INDEX: string = 'index:\'80a0d1a0-b6bd-11ec-9d07-93da7c667a0c\'';

  // Define the environments that users of the utility can request to open Kibana in
  static readonly KIBANA_PROD_HOST: string = 'kibana-prod';
  static readonly KIBANA_INT_HOST: string = 'kibana-int';
  static readonly KIBANA_FEDRAMP_HOST: string = 'kibana-fed';
}
