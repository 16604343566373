import { OverviewComponent } from './overview/overview.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { LocusStaticComponent } from './locus-static-component/locus-static-component.component';
import { CallSummaryComponent } from './call-summary/call-summary.component';
import { JournalRowsComponent } from './journal-rows/journal-rows.component';
import { LocusMeetingInfoComponent } from './locus-meeting-info/locus-meeting-info.component';
import { WalkJournalComponent } from './walk-journal/walk-journal.component';
import { ShowLegendComponent } from './show-legend/show-legend.component';
import { ShowCountPlotComponent } from './show-count-plot/show-count-plot.component';
import { ShowAttrsComponent } from './show-attrs/show-attrs.component';
import { GetDTOsComponent } from './get-dtos/get-dtos.component';
import { MeetingCltComponent } from "./meeting-clt/meeting-clt.component";
import { BreakoutSummaryComponent } from './breakout-summary/breakout-summary.component';
import { AboutInfoComponent } from './about-info/about-info.component';

const routes: Routes = [
  { path: '', component: OverviewComponent, canActivate: [ AuthGuard ] },
  { path: 'about', component: AboutInfoComponent },
  { path: 'overview', component: OverviewComponent},
  { path: 'login', component: LoginComponent },
  { path: 'locusStatic', component: LocusStaticComponent },
  { path: 'meetingClt', component: MeetingCltComponent },
  { path: 'callSummary', component: CallSummaryComponent },
  { path: 'journalRows', component: JournalRowsComponent },
  { path: 'locusMeetingInfo', component: LocusMeetingInfoComponent },
  { path: 'walkJournal', component: WalkJournalComponent },
  { path: 'showLegend', component: ShowLegendComponent },
  { path: 'showCountPlot', component: ShowCountPlotComponent },
  { path: 'showAttrs', component: ShowAttrsComponent },
  { path: 'getDTOs', component: GetDTOsComponent },
  { path: 'getBreakoutSummary', component: BreakoutSummaryComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

