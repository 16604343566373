import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {JournalToolBackendRequestsService} from '../_services/journal-tool-backend-requests.service';
import {JstreeSpinnerComponent} from '../jstree-spinner/jstree-spinner.component';
import {UiUtils} from '../_utilities/ui-utils';

@Component({
  selector: 'app-show-legend',
  templateUrl: './show-legend.component.html',
  styleUrls: ['./show-legend.component.scss']
})
export class ShowLegendComponent implements OnInit {

  data = {};
  walkJournalData = null;
  jsonView = true;

  constructor(private http: HttpClient, private route: ActivatedRoute,
              private backendService: JournalToolBackendRequestsService,
              private jstreeSpinner: JstreeSpinnerComponent) {
  }

  async ngOnInit() {

    document.title = `Legend : ${this.route.snapshot.queryParams['locusID']} @ ${this.route.snapshot.queryParams['date']}`;

    UiUtils.setWaitCursor();

    if (!window['data']) {
      let request = await this.backendService.getJournal(
        this.route.snapshot.queryParams['environment'],
        this.route.snapshot.queryParams['locusID'],
        this.route.snapshot.queryParams['date']);

      if (request['status'] === this.backendService.FAILURE) {
        console.log(request['errorMessage']);
      } else {
        this.walkJournalData = request['data'];
      }
    } else {
      this.walkJournalData = window['data'];
    }

    if (this.walkJournalData !== null) {
      this.data = {};
      this.data['participantInfoList'] = this.walkJournalData['response']['participantInfoList'];
      this.data['Legend'] = this.makeLegend();
    }

    this.jstreeSpinner.hide();
    UiUtils.setDefaultCursor();
  }

  makeLegend() {
    let legend = {};
    legend['isArchived'] = this.walkJournalData['response']['isArchived'];
    legend['Journal Rows:'] = this.walkJournalData['response']['rowCountActual'];

    let userMappings = {};
    for (let i in this.walkJournalData['userMap']) {
      let uid = null;
      for (let j in this.walkJournalData['idMap']) {
        if (this.walkJournalData['idMap'][j] == i) {
          uid = j;
        }
      }
      userMappings[this.walkJournalData['userMap'][i]] = i + ' / ' + uid;
    }
    legend['User Mappings:'] = userMappings;

    let deviceMappings = {};

    for (let d of this.walkJournalData['response']['deviceInfoList']) {
      let toAdd = []
      let userName = this.walkJournalData['deviceNameMap'][d['deviceUrl']];
      toAdd.push('Device Url = ' + d['deviceUrl']);
      toAdd.push('User Agent = ' + d['userAgent']);
      toAdd.push('Type = ' + d['userAgentType']);
      toAdd.push('Platform = ' + d['userAgentPlatform']);
      toAdd.push('Version = ' + d['userAgentVersion']);

      deviceMappings[userName] = toAdd;
    }
    legend['Device Mappings:'] = deviceMappings;
    return legend;
  }

  public toggleView(textViewId, jsTreeId) {
    if (this.jsonView) {
      document.getElementById(textViewId).style.display = "block";
      document.getElementById(jsTreeId).style.display = "none";
      this.jsonView = false;
    } else {
      document.getElementById(textViewId).style.display = "none";
      document.getElementById(jsTreeId).style.display = "block";
      this.jsonView = true;
    }
  }

}
