import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {EnvironmentService} from '../_services/environment.service';
import {MatDialog} from '@angular/material/dialog';
import {JournalToolBackendRequestsService} from '../_services/journal-tool-backend-requests.service';
import {JstreeSpinnerComponent} from '../jstree-spinner/jstree-spinner.component';
import {UiUtils} from '../_utilities/ui-utils';

@Component({
  selector: 'app-journal-rows',
  templateUrl: './journal-rows.component.html',
  styleUrls: ['./journal-rows.component.scss']
})
export class JournalRowsComponent implements OnInit {

  data = {};
  numRows = 0;
  jsonView = true;

  constructor(private http: HttpClient, private route: ActivatedRoute,
              private dialog: MatDialog,
              private environment: EnvironmentService,
              private backendService: JournalToolBackendRequestsService,
              private jstreeSpinner: JstreeSpinnerComponent) {
  }

  async ngOnInit() {

    document.title = `Journal Rows : ${this.route.snapshot.queryParams['locusID']} @ ${this.route.snapshot.queryParams['date']}`;

    UiUtils.setWaitCursor();

    if (!window['data']) {
      let request = await this.backendService.getJournalRows(this.route.snapshot.queryParams['environment'], this.route.snapshot.queryParams['locusID'], this.route.snapshot.queryParams['date'], this.route.snapshot.queryParams['unrollRows']);
      this.data = request['data'];
    } else {
      this.data = window['data'];
    }
    this.numRows = this.data['rowList'].length;
    for (let i in this.data['rowList']) {
      if (this.data['rowList'][i]['attrs'] != undefined) {
        this.data['rowList'][i]['attrs'] = JSON.parse(this.data['rowList'][i]['attrs']);
      }
    }

    this.jstreeSpinner.hide();

    UiUtils.setDefaultCursor();
  }

  async copyJournalRows() {
    let targetEnvironment = this.route.snapshot.queryParams['environment'];
    let baseUrl = '';
    baseUrl = this.environment.getDevJournalServiceUrl();
    const urlToQuery = baseUrl + '/api/v1/loci/copyJournalRows/' + this.route.snapshot.queryParams['locusID'];
    let incomingData = await this.http.get(
      urlToQuery,
      {
        params: {
          'environment': targetEnvironment,
          'date': this.route.snapshot.queryParams['date'],
          'unrollRows': this.route.snapshot.queryParams['unrollRows'],
          'idType': this.route.snapshot.queryParams['idType'],
          'numRows': this.numRows.toString()
        }
      })
      .pipe().toPromise().catch((error) => {
        // let errorMessage = 'Cannot copy Journal Rows';
        // const dialogRef = this.dialog.open(DialogBoxComponent, {
        //   width: '500px',
        //   data: { message: errorMessage }
        // });
        console.log(error);
      });
  }

  public toggleView(textViewId, jsTreeId) {
    if (this.jsonView) {
      document.getElementById(textViewId).style.display = "block";
      document.getElementById(jsTreeId).style.display = "none";
      this.jsonView = false;
    } else {
      document.getElementById(textViewId).style.display = "none";
      document.getElementById(jsTreeId).style.display = "block";
      this.jsonView = true;
    }
  }

}
