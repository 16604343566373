<mat-toolbar class="flex-container">
  <mat-toolbar-row style="justify-content: center">
    <div>
      <a href="/"><img src="../assets/images/cisco-logo-blue.svg"></a>
    </div>
    <div class="nav-link">
      <button mat-button aria-label="Overview" routerLinkActive="active" routerLinkActiveOptions="{exact:true}" routerLink="/overview">Overview</button>
    </div>
    <div class="nav-link">
      <button mat-button aria-label="About" routerLinkActive="active" routerLinkActiveOptions="{exact:true}" routerLink="/about">About</button>
    </div>
    <div class="nav-link">
      <button mat-button aria-label="Toolbar" (click)="this.toggleToolbar()">Toolbar</button>
    </div>
    <div style="width: 35rem;"></div>
    <div class="nav-link">
      <button mat-icon-button aria-label="Toggle light or dark theme" (click)="this.switchTheme()"
              matTooltip="Toggle light or dark theme">
        <mat-icon>format_color_fill</mat-icon>
      </button>
    </div>
    <div class="nav-link">
      <form #logoutForm ngNoForm action="{{logoutUrl}}" method="POST">
        <input type="hidden" name="token" value="{{token}}">
        <input type="hidden" name="goto" value="{{redirectUrl}}">
        <button mat-raised-button color="primary" aria-label="Log out" (click)="processLogout()">Log Out</button>
      </form>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
