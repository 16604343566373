<div class="about">
  <mat-table [dataSource]="journalToolDependencies">
    <ng-container matColumnDef="dependency">
      <mat-header-cell *matHeaderCellDef style="font-size: large"> Dependency </mat-header-cell>
      <mat-cell *matCellDef="let row" style="font-size: large"> {{row.dependency}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="version">
      <mat-header-cell *matHeaderCellDef style="font-size: large"> Version </mat-header-cell>
      <mat-cell *matCellDef="let row" style="font-size: large"> {{row.version}} </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="['dependency', 'version']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['dependency', 'version']"></mat-row>
  </mat-table>
</div>
