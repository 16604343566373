import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { JournalToolBackendRequestsService } from '../_services/journal-tool-backend-requests.service';
import {JstreeSpinnerComponent} from '../jstree-spinner/jstree-spinner.component';
import {UiUtils} from '../_utilities/ui-utils';

@Component({
  selector: 'app-breakout-summary',
  templateUrl: './breakout-summary.component.html',
  styleUrls: ['./breakout-summary.component.scss']
})
export class BreakoutSummaryComponent implements OnInit {

  constructor(private http: HttpClient,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private backendService: JournalToolBackendRequestsService,
              private jstreeSpinner: JstreeSpinnerComponent) {}


  data = {};
  jsonView: boolean = true;

  async ngOnInit() {

    document.title = `Breakout Summary : ${this.route.snapshot.queryParams['bid']}, ${this.route.snapshot.queryParams['locusID']} @ ${this.route.snapshot.queryParams['date']}`;

    UiUtils.setWaitCursor();

    if (!window['data']) {
      let request = await this.backendService.getBreakoutSummary(this.route.snapshot.queryParams['environment'], this.route.snapshot.queryParams['locusID'], this.route.snapshot.queryParams['date'], this.route.snapshot.queryParams['bid']);
      this.data = request['data'];
    } else {
      this.data = window['data'];
    }

    this.jstreeSpinner.hide();
    UiUtils.setDefaultCursor();
  }

  public toggleView(textViewId, jsTreeId) {
    if (this.jsonView) {
      document.getElementById(textViewId).style.display = "block";
      document.getElementById(jsTreeId).style.display = "none";
      this.jsonView = false;
    } else {
      document.getElementById(textViewId).style.display = "none";
      document.getElementById(jsTreeId).style.display = "block";
      this.jsonView = true;
    }
  }
}
