import { Injectable, Injector } from '@angular/core';
import { EnvironmentService } from '../_services/environment.service';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AuthenticationService } from '../_services/authentication/authentication.service';
// import { switchMap, tap } from 'rxjs/operators';
// import { of } from 'rxjs/Observable/of';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private environment: EnvironmentService, private authenticationService: AuthenticationService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let accessToken = window.localStorage.getItem('access_token');
    const access_token_expiration = parseInt(localStorage.getItem('access_token_expiration'));
    const refresh_token_expiration = parseInt(localStorage.getItem('refresh_token_expiration'));
    const cur_date = new Date().getTime();
    let log_stamp = 'journal-client_' + new Date().toISOString();
    console.log('in auth interceptor');
    if (!accessToken || req.headers.keys().includes('Authorization')) {// if there is no access token in local storage or authorization is already set, then this request was either trying to get the access token for the first time or has refresh authorization which means we do not want to override the headers.
      const authReq = req.clone({ headers: req.headers.set('trackingid', log_stamp) });
      console.log('auth interceptor a');
      return next.handle(authReq);
    } else {
      if (cur_date < access_token_expiration) {
        console.log('auth interceptor b');
        const authReq = req.clone({ headers: new HttpHeaders({ 'trackingid': log_stamp, 'Authorization': `Bearer ${accessToken}` }) });
        return next.handle(authReq);
      } else if (cur_date > access_token_expiration && cur_date < refresh_token_expiration) {
        console.log('in auth interceptor c');
        return from(this.refreshAccessTokenHelper(req, next, log_stamp));
      } else {
        this.authenticationService.logout(true);
      }
    }
  }

  // had issues with request being sent out with wrong old authorization header before refactoring this out
  // because the authentication service had not finished setting the localStorage access_token item before we did next.handle
  // the intercept function itself cannot be async and creating another async function and waiting for it with the 'from' operator
  // seemed to be a popular stackoverflow solution.
  async refreshAccessTokenHelper(req: HttpRequest<any>, next: HttpHandler, log_stamp) {
    console.log('refresh token called from auth interceptor');
    await this.authenticationService.refreshAccessToken().toPromise();
    console.log('refresh token called from auth interceptor has completed');
    let accessToken = window.localStorage.getItem('access_token');
    const authReq = req.clone({ headers: new HttpHeaders({ 'trackingid': log_stamp, 'Authorization': `Bearer ${accessToken}` }) });
    return next.handle(authReq).toPromise();
  }
}
