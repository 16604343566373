<!-- the router links should work and is a better more angular way of routing. We should be able to use
  the [state]="some_object" at an attribute on the routerLink to pass data. This is a feature of Angular 7,
  but I never got it to work. I also tried pretty much every other way of passing data to the other components too.
 -->
<div class="help-button" matTooltipClass="key-tooltip" [matTooltip]="getKeyBindingText()" matTooltipPosition="left" matTooltipShowDelay="0"> ? </div>
<span class="flex-container-row">
<table>
  <tr>
    <td>Environment:</td>
    <td style="padding-left: 10px">{{ env }}</td>
    <td style="padding-left: 20px">Journal Version:</td>
    <td style="padding-left: 10px">{{ journalVersion }}</td>
  </tr>
  <tr>
    <td>Locus Id:</td>
    <td style="padding-left: 10px">{{ locusId }}</td>
    <td style="padding-left: 20px">Start Time:</td>
    <td style="padding-left: 10px">{{ startTime }}</td>
  </tr>
</table>
</span>
<span class="flex-container-row">
<table>
  <tr>
    <td>Joined Participants:</td>
    <td style="padding-left: 10px"><input readonly type="text" [(ngModel)]="joinedParticipantsCount" style="width: 50px; text-align: center" /></td>
    <td style="padding-left: 20px">Connected Devices:</td>
    <td style="padding-left: 10px"><input readonly type="text" [(ngModel)]="connectedDevicesCount" style="width: 50px; text-align: center" /></td>
    <td style="padding-left: 20px"><button mat-raised-button color="primary" aria-label="Plot Counts" [disabled]="!jumpFormEnabled" (click)="showCountPlot()" style="font-size: smaller;height: auto">Plot Counts</button></td>
    <td style="padding-left: 20px">Locus State:</td>
    <td style="padding-left: 10px"><input readonly type="text" [(ngModel)]="locusState" style="width: 150px; text-align: center" /></td>
  </tr>
</table>
</span>

<div class="flex-container">
  <div class="flex-container-row">
    <button mat-raised-button color="primary" aria-label="Show Legend" (click)="showLegend()">Show Legend</button>
    <button mat-raised-button color="primary" aria-label="Show Attrs" (click)="showAttrs()">Show Attrs</button>
    <button mat-raised-button color="primary" aria-label="Locus Static" (click)="openLocusStatic()">Locus Static</button>
    <button mat-raised-button color="primary" aria-label="Get DTOs" (click)="getDTOs()">Get DTOs</button>
    <button mat-raised-button color="primary" aria-label="Get Call Flow Diagram" (click)="getCallFlowDiagram()"
            [matTooltip]="'Generate Call Flow Diagram'+dynamicTip" matTooltipClass="tooltip">Get Call Flow Diagram</button>
    <button mat-raised-button color="primary" aria-label="CLT Summary" (click)="getMeetingClt()">CLT Summary</button>
    <button mat-raised-button color="primary" aria-label="Kibana"
            *ngIf="(jumpFormEnabled && kibanaEnabled)" [matMenuTriggerFor]="kibanaMenu">Kibana</button>
    <mat-menu #kibanaMenu>
      <button mat-menu-item aria-label="kibana option"
              *ngFor="let option of this.kibanaDropDownOptions;"
              (click)="onKibanaClick(option)">{{option}}</button>
    </mat-menu>
    <button mat-raised-button color="primary" aria-label="Breakout Summary"
            *ngIf="!breakoutHidden" (click)="showBreakoutSummary()">Breakout Summary</button>
    <button mat-raised-button color="primary" aria-label="Column Reset"
            (click)="resizeJournalAndUserGrid()">Reset Column Size</button>
  </div>
</div>

<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/ag-grid-community/dist/ag-grid-community.min.noStyle.js"></script>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/ag-grid-community@32.1.0/styles/ag-grid.css">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/ag-grid-community@32.1.0/styles/ag-theme-balham.css">

<div id="div_userRowsGrid">
  <ag-grid-angular
    #userRowsGrid
    class="user-journal"
    (gridReady)="onUserRowsGridReady($event)"
    [ngClass]="agTheme$ | async"
    [gridOptions]="userRowsGridOptions"></ag-grid-angular>
</div>

<div class="flex-container">
  <div class="flex-container-row">
    <div class="flexbox-item">
      User/Device/Event/Attrs Search:
    </div>
    <div class="flexbox-item">
      <input [disabled]="!jumpFormEnabled" id="search-input" class="jump-input" type="text" [(ngModel)]="searchString" (ngModelChange)="searchFunction($event)" />
    </div>
    <div class="flexbox-item">
      <input [disabled]="!jumpFormEnabled" style="padding: 5px;" type="checkbox" [(ngModel)]="keepTransactionsCheckBox" (ngModelChange)="keepTransactionsChecked($event)"/>
    </div>
    <div class="flexbox-item">
      Keep Transaction
    </div>
    <div class="flexbox-item"></div>
    <div class="flexbox-item"></div>
    <div class="flexbox-item">
      Jump To Timestamp:
    </div>
    <div class="flexbox-item">
      <form class="flexbox-item" (ngSubmit)="scrollFunction()">
        <input [disabled]="!jumpFormEnabled" class="jump-input" style="padding: 5px;" #timeInput type="text" placeholder="YYYY-MM-DDThh"
               [value]="scrollTimeString"
               (input)="timeChangeFunction(timeInput.value)">
      </form>
    </div>
    <div class="flexbox-item">
        <button [disabled]="!jumpFormEnabled" mat-raised-button color="primary" (click)="scrollFunction()" style="margin-left: 10px" aria-label="Jump">Jump</button>
    </div>
    <div class="flexbox-item">
      <button [disabled]="!jumpFormEnabled" mat-raised-button color="primary" (click)="scrollToTop()" aria-label="Top">Top</button>
    </div>
    <div class="flexbox-item">
      <button [disabled]="!jumpFormEnabled" mat-raised-button color="primary" (click)="scrollToBottom()" aria-label="Bottom">Bottom</button>
    </div>
    <div class="flexbox-item">
      <span style="color: red;font-size: smaller" [hidden]="!showScrollTimeErrorMessage">
        Timestamp must match format: 'YYYY-MM-DDThh...' (minutes, seconds, milliseconds optional)</span>
    </div>
  </div>
</div>

<div id="div_journalRowsGrid">
  <ag-grid-angular
    #journalRowsGrid
    class="walk-journal"
    (gridReady)="onJournalRowsGridReady($event)"
    [ngClass]="agTheme$ | async"
    [gridOptions]="journalRowsGridOptions"></ag-grid-angular>
</div>
