export class MeetingCltConstants {

  static readonly filterGroups = [
    {
      label: "Show All",                       // button label
      entries: {                               // the ALPHABETICAL list of CLTs to display for this group
      }
    },
    {
      label: "Overview",                       // button label
      entries: {                               // the ALPHABETICAL list of CLTs to display for this group
        "confAutoLock": "Auto Lock Enabled",
        "confAutoLockMinutes": "Auto Lock Enabled (minutes)",
        "enableE2eeV2": "End to End Encryption V2",
        "isConvergedArchitectureEnabled": "Converged Meeting",
        "webexMeetingType": "Meeting Type",
        "webexServiceType": "Service Type"
      }
    },
    {
      label: "Eureka Deprecation",
      entries: {
        "enableLocusUpdateMeetingStatus": "Meeting status update to MRS",
        "enableMpsHostAutoAssignment": "Host management, auto assignment",
        "enableMpsManageMeetingLifecycle": "Meeting Lifecycle",
        "enablePresenterAutoAssignFullyConverged": "Presenter management",
        "fullyConvergedAutoStartForSessionLimitAndJbhEnabled": "Auto start meetings",
        "isConvergedArchitectureEnabled": "Converged Meeting",
        "mpsControlMeetingSize": "Meeting size control"
      }
    },
    {
      label: "Recording",
      entries: {
        "allowControlAutoRecording": "Automatic recording enable/disable",
        "autoRecordingEnabled": "Automatic recording",
        "convergedLocalRecordingEnabled": "Converged local recording",
        "convergedRecordingEnabled": "Converged Recording",
        "isConvergedArchitectureEnabled": "Converged Meeting",
        "localRecordingEnabled": "Local Recording",
        "recordingDisclaimerEnabled": "Recording Disclaimer",
        "recordingDisclaimerUrl": "Recording Disclaimer URL",
        "recordingEnabled": "Recording",
        "recordingStreamingServiceUrl": "RSS URL"
      }
    }
  ];
}
