import {JournalToolBackendRequestsService} from '../_services/journal-tool-backend-requests.service';
import {EnvironmentService} from '../_services/environment.service';
import {MatDialog} from '@angular/material/dialog';
import {DialogBoxComponent} from '../dialog-box/dialog-box.component';
import {Router} from '@angular/router';

export class UiUtils {

  private static waitCursors: HTMLStyleElement[] = [];

  // Create wait cursor styles.
  static {
    UiUtils.addWaitCursor('body');
    UiUtils.addWaitCursor('button:hover');
    UiUtils.addWaitCursor('.ag-root');
  }

  public static addWaitCursor(cssSelector: string) {
    const css: string = cssSelector + '{ cursor: wait }';
    const waitCursor: HTMLStyleElement = document.createElement('style');
    waitCursor.appendChild(document.createTextNode(css));
    UiUtils.waitCursors.push(waitCursor);
  }

  public static setDefaultCursor() {
    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    for (const cursor of this.waitCursors) {
      head.removeChild(cursor);
    }
  }

  public static setWaitCursor() {
    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    for (const cursor of this.waitCursors) {
      head.appendChild(cursor);
    }
  }

  public static async openLocusStatic(router: Router,
                                      dialog: MatDialog,
                                      backendService: JournalToolBackendRequestsService,
                                      environment: EnvironmentService,
                                      locusId: string) {
    UiUtils.setWaitCursor();
    let incomingData = await backendService.getLocusStatic(environment, locusId);
    if (incomingData['status'] === backendService.FAILURE) {
      dialog.open(DialogBoxComponent, {
        width: '500px',
        data: { message: incomingData['errorMessage'] }
      });
    } else if (incomingData['data'] === null) {
      dialog.open(DialogBoxComponent, {
        width: '500px',
        data: {message: 'Locus Static not found'}
      });
    } else {
      let url = router.createUrlTree(['/locusStatic'], {
        queryParams: {
          'locusID': locusId,
          'environment': environment
        }
      });
      let locusStaticWindow = window.open(url.toString(), '_blank');
      locusStaticWindow['data'] = incomingData['data'];
    }
    UiUtils.setDefaultCursor();
  }

  public static async openCallFlowDiagram(dialog: MatDialog,
                                          backendService: JournalToolBackendRequestsService,
                                          environment: EnvironmentService,
                                          locusId: string, lastActive: string,
                                          trackingID: string, beginningTime: string, endingTime: string,
                                          clearCache: boolean) {
    UiUtils.setWaitCursor();
    let incomingData = await backendService.getCallFlowDiagram(environment, locusId, lastActive, trackingID, beginningTime, endingTime, clearCache);
    UiUtils.setDefaultCursor();
    if (incomingData['status'] === backendService.FAILURE) {
      dialog.open(DialogBoxComponent, {
        width: '500px',
        data: {message: incomingData['errorMessage']}
      });
    } else if (incomingData['data'] === null) {
      dialog.open(DialogBoxComponent, {
        width: '500px',
        data: {message: 'Call Flow Diagram not found'}
      });
    } else {
      let win = window.open();
      win.document.write(incomingData['data']);
      win.document.close();
      win.addEventListener('load', () => {
        win.document.title = `Call Flow Diagram : ${locusId} @ ${lastActive}`;
        win.document.querySelector('svg').addEventListener('click', (event) => {
          event.preventDefault();
        });
      });
    }
  }

}
