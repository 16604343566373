import { Injectable } from '@angular/core';
import { EnvironmentService } from 'src/app/_services/environment.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import * as _ from 'lodash';
import { AuthInfoService, IAuthInfo } from '../_services/authInfo.service';

export interface IUserInfo {
  avatar: string;
  status: string;
  email: string;
  name: string;
  userName?: string;
  roles: string;
  onboardDate: string;
}

export interface IUserRequest {
  displayName: string;
  email: string;
  licenses: [];
  onboardMethod: string;
  name: { givenName: string, familyName: string };
  userEntitlements: [];
  role?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private UsersList = new BehaviorSubject<IUserInfo[]>([]);
  public usersListData$ = this.UsersList.asObservable();
  public isUsersDataLoaded = false;

  constructor(
    private http: HttpClient,
    private authInfoService: AuthInfoService,
    private environment: EnvironmentService,
    // private alertService: AlertService,
  ) {
    this.initailizeUsers();
  }

  public initailizeUsers() {
    this.authInfoService.authInfoData$
      .subscribe((data: IAuthInfo) => {
        const orgId = data.orgId;
        if (orgId) {
          this.getUsersList(orgId);
        }
      });
  }

  public getLoggedInUserInfo(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const url = `https://${this.environment.getIdentityUrl()}/identity/scim/v1/Users/me`;

    return this.http.get(url);
  }

  public getUsersList(orgId): Observable<IUserInfo[]> {
    // tslint:disable-next-line:max-line-length
    const url = `https://${this.environment.getIdentityUrl}/identity/scim/${orgId}/v1/Users?&attributes=name,userName,userStatus,entitlements,displayName,photos,roles,active,trainSiteNames,linkedTrainSiteNames,licenseID,userSettings,userPreferences&filter=active%20eq%20true%20or%20displayName%20sw%20%22xz%22&count=100&sortBy=name&sortOrder=ascending`;

    this.http.get(url).subscribe(
      (data: any) => {
        const usersData = data.Resources.map((userData: IUserInfo) => {
          return this.formatUsersData(userData);
        });
        // call getFiltered
        this.UsersList.next(usersData);
      },
      err => {
        // this.alertService.error('Error', err.message, {
        //   ariaLabel: 'Close Alert',
        //   orderNewest: false,
        // });
      });
    return this.usersListData$;
  }

  public filterUsers(value) {
    // object email
    const users = this.UsersList.value.filter((user) => {
      const domain = user.email.split('@')[1];
      return domain === value.domain;
    });

    this.UsersList.next(users);
  }

  // TODO: shrazzac: Refactor using pipes
  // TODO: May 16th 2019 - Find where we can get avatar/profile picture url
  private formatUsersData(usersData): IUserInfo {
    const isPendingActivation = _.get(usersData, 'accountStatus[0]', undefined) === 'pending';
    const name = _.get(usersData, 'name.familyName', '') + ', ' + _.get(usersData, 'name.givenName', '');
    const hasAdminRole = _.has(usersData, 'roles') ? usersData.roles.includes('id_full_admin') : undefined;
    const onboardDate = _.has(usersData, 'meta.created') ? usersData.meta.created + '' : '';
    const avatar = 'someUrlHere';

    return {
      avatar,
      status: isPendingActivation ? 'Pending' : 'Active',
      name,
      email: usersData.userName,
      roles: hasAdminRole ? 'Admin' : 'User',
      onboardDate,
    };
  }

  public addUsers(users: IUserRequest[]) {
    const url = `https://${this.environment.getAtlasUrl()}/admin/api/v1/organization/${this.authInfoService.orgId}/users/onboard`;

    const userList = { users };

    return this.http.post(url, userList).subscribe(
      () => {
        // this.alertService.success('Success', 'The user has been added', {
        //   ariaLabel: 'Close Alert',
        // });
        this.getUsersList(this.authInfoService.orgId);
      },
      err => {
        // this.alertService.error('Error', err.message, {
        //   ariaLabel: 'Close Alert',
        //   orderNewest: false,
        // });
      });
  }

  public removeUser(userEmail: any) {
    const url = `https://${this.environment.getAtlasUrl()}/admin/api/v1/organization/${this.authInfoService.orgId}/user?email=${userEmail}`;

    return this.http.delete(url).subscribe(
      () => {
        // this.alertService.success('Success', 'The user has been removed', {
        //   ariaLabel: 'Close Alert',
        // });
        this.getUsersList(this.authInfoService.orgId);
      },
      err => {
        // this.alertService.error('Error', err.message, {
        //   ariaLabel: 'Close Alert',
        //   orderNewest: false,
        // });
      });
  }

  public editUser() {
    const url = '';
    const body = {};

    return this.http.patch(url, body).subscribe((res) => {

    });
  }

}
