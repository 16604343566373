import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { EnvironmentService } from './environment.service';

interface IUserInfo {
  userName: string;
  id: string;
  displayName: string;
  roles: any[];
}

export interface IAuthInfo {
  name?: string;
  orgId?: string;
  orgName?: string;
  uuid?: string;
}

export interface ICustomerInfo {
  customerId?: string;
  customerName?: string;
  customerSetupStatus?: string;
  customerType?: string;
  userId?: string;
}

export interface ICustomersReponse {
  customers: ICustomerInfo[];
}

@Injectable({ providedIn: 'root' })
export class AuthInfoService {
  private UserAuthInfo = new BehaviorSubject<IAuthInfo>({});
  public authInfoData$ = this.UserAuthInfo.asObservable().pipe(share());
  public isAuthInfoLoaded = false;
  public orgId: string;
  public userId: string;

  private UrlConfig;

  constructor(
    // private alertService: AlertService,
    private http: HttpClient, private environment: EnvironmentService
  ) {
    this.UrlConfig = {
      authInfo: `https://${this.environment.getAtlasUrl()}/admin/api/v1/userauthinfo`,
      customersUrl: `https://${this.environment.getAtlasUrl()}/admin/api/v1/customers`,
    };
  }

  public getAuthInfo() {
    this.http.get(this.UrlConfig.authInfo).subscribe(
      (data: IAuthInfo) => {
        if (data) {
          this.isAuthInfoLoaded = true;
        }
        this.orgId = data.orgId;
        this.userId = data.uuid;
        this.UserAuthInfo.next(data);
      },
      err => {
      });
  }

  public getCurrentUserInfo() {
    return this.authInfoData$.pipe(
      map((data: IAuthInfo) => data),
    );
  }

  public getCustomerInfo(user: IAuthInfo) {
    const orgId = user.orgId;
    const userId = user.uuid;
    if (!orgId) {
      return;
    }
    // return this.http.get(this.UrlConfig.customersUrl, {
    //   params: {
    //     orgId,
    //   },
    // }).pipe(
    //   map( (data: ICustomersReponse) => {
    //     const customer: ICustomerInfo = data.customers[0];
    //     customer.userId = userId;
    //     return customer;
    //   })
    // ).toPromise();
  }

}
