import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {JournalConstants} from "../_constants/journal-constants";

@Component({
  selector: 'btn-cell-renderer',
  template: `
    <button mat-raised-button color="accent" aria-label="Journal Jump" (click)="onClick($event)"
                  *ngIf="!hideButton">Jump</button>
  `,
  styleUrls: ['./journal-jump-button.component.scss']
})

export class JournalJumpRendererComponent implements ICellRendererAngularComp {
  private params: any;
  public hideButton = 'hidden';

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (params.value) {
      if (params.value === JournalConstants.COLUMN_JUMP) {
        this.hideButton = '';
      }
    }
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
      };
      this.params.onClick(params);
    }
  }

  refresh(params: any): boolean {
    return true;
  }
}

