
<mat-menu #environmentMenu="matMenu">
  <button *ngFor="let e of ENVIRONMENT_LIST" (click)="setEnvironment(e)" mat-menu-item>{{e}}</button>
</mat-menu>

<mat-menu #dateRangeMenu="matMenu">
  <button (click)="setDateRange(HOUR_1)" mat-menu-item>{{HOUR_1}}</button>
  <button (click)="setDateRange(HOUR_2)" mat-menu-item>{{HOUR_2}}</button>
  <button (click)="setDateRange(HOUR_6)" mat-menu-item>{{HOUR_6}}</button>
  <button (click)="setDateRange(DAY_1)" mat-menu-item>{{DAY_1}}</button>
  <button (click)="setDateRange(DAY_2)" mat-menu-item>{{DAY_2}}</button>
  <button (click)="setDateRange(DAY_3)" mat-menu-item>{{DAY_3}}</button>
  <button (click)="setDateRange(WEEK_1)" mat-menu-item>{{WEEK_1}}</button>
  <button (click)="setDateRange(WEEK_2)" mat-menu-item>{{WEEK_2}}</button>
  <button (click)="setDateRange(WEEK_4)" mat-menu-item>{{WEEK_4}}</button>
  <button (click)="setDateRange(WEEK_6)" mat-menu-item>{{WEEK_6}}</button>
  <button (click)="setDateRange(WEEK_8)" mat-menu-item>{{WEEK_8}}</button>
  <button (click)="setDateRange(ALL_TIME)" mat-menu-item>{{ALL_TIME}}</button>
</mat-menu>

<mat-menu #idTypeMenu="matMenu">
  <button (click)="setidType('LOCUS_ID')" mat-menu-item>"LOCUS_ID"</button>
  <button (click)="setidType('MEETING_ID')" mat-menu-item>"MEETING_ID"</button>
  <button (click)="setidType('SIP_URI')" mat-menu-item>"SIP_URI"</button>
</mat-menu>

<mat-menu #locusIDMenu="matMenu">
  <!-- I think the journal tool populates this with previous locus ids the user inputted, will try to add this later -->
  <button *ngFor="let curID of previouslyEnteredLocusIdList" (click)="setLocusID(curID)" mat-menu-item>
    <span class="overview-locus-menu-item">{{curID}}</span>
  </button>
</mat-menu>

<mat-menu #lastActiveMenu="matMenu">
  <button *ngFor="let time of startTimes" (click)="setLastActive(time)" mat-menu-item>{{time}}</button>
</mat-menu>

<mat-menu #trackingIDBox="matMenu">
  <button *ngFor="let tid of trackingID" (click)="setTrackingID(tid)" mat-menu-item>{{tid}}</button>
</mat-menu>

<mat-menu #begTimeBox="matMenu">
  <button *ngFor="let time of beginningTime" (click)="setBeginningTime(time)" mat-menu-item>{{time}}</button>
</mat-menu>

<mat-menu #endTimeBox="matMenu">
  <button *ngFor="let time of endingTime" (click)="setEndingTime(time)" mat-menu-item>{{time}}</button>
</mat-menu>

<br />

<div class="flex-container-row">
  <div class="overview-label">Environment</div>
  <div [matMenuTriggerFor]="environmentMenu" class="overview-input">
    <input class="overview-select" type="text" [readonly]=true [(ngModel)]="targetEnvironment" />
    <button mat-icon-button aria-label="Select an environment">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
  <button (click)="walkJournal()" id="walkJournal" mat-raised-button color="primary" aria-label="Walk Journal" class="button"
          [matTooltip]="'Gets journal associated with ID and Last Active values'+dynamicTip" matTooltipClass="tooltip">
    Walk Journal</button>
  <div style="margin: 1rem;"></div>
  <button (click)="getJournalRows(true)" mat-raised-button color="primary" aria-label="Get Journal Rows" class="button"
          matTooltip="Gets raw journal rows" matTooltipClass="tooltip">
    Get Journal Rows</button>
</div>

<div class="flex-container-row">
  <div class="overview-label">Date Range</div>
  <div [matMenuTriggerFor]="dateRangeMenu" class="overview-input">
    <input type="text" [(ngModel)]="dateRange" class="overview-select" />
    <button mat-icon-button aria-label="Select an environment">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
  <button (click)="getCallSummary()" mat-raised-button color="primary" aria-label="Get Locus Call Summary" class="button"
          matTooltip="Gets locus call summary associated with ID and Last Active values" matTooltipClass="tooltip">
    Get Locus Call Summary</button>
  <div style="margin: 1rem;"></div>
  <button (click)="getJournalRows(false)" mat-raised-button color="primary" aria-label="Get Journal Roll Up" class="button"
          matTooltip="Gets journal rows without breaking them into single rows" matTooltipClass="tooltip">
    Get Journal Roll Up</button>
</div>

<div class="flex-container-row">
  <div class="overview-label">ID Type</div>
  <div class="overview-input" [matMenuTriggerFor]="idTypeMenu">
    <input type="text" [(ngModel)]="idType" class="overview-select" />
    <button mat-icon-button aria-label="Select an environment">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
  <button (click)="getLocusStatic()" mat-raised-button color="primary" aria-label="Get Locus Static" class="button"
          matTooltip="Gets locus static entry associated with ID value" matTooltipClass="tooltip">
    Get Locus Static</button>
  <div style="margin: 1rem;"></div>
  <button (click)="getLocusMeetingInfo()" mat-raised-button color="primary" aria-label="Get Locus Meeting Info" class="button"
          matTooltip="Gets locus meeting information from MRS" matTooltipClass="tooltip">
    Get Locus Meeting Info</button>
</div>

<div class="flex-container-row">
  <div class="overview-label">ID Value</div>
  <div class="overview-input">
    <input type="text" [(ngModel)]="locusID" class="overview-select" autoselect />
    <button mat-icon-button [matMenuTriggerFor]="locusIDMenu" aria-label="Select a Locus ID">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
  <button (click)="getStartTimes()" mat-raised-button color="primary" aria-label="Get Start Times" class="button"
          matTooltip="Searches currently selected environment for start times." matTooltipClass="tooltip">
    Get Start Times</button>
  <div style="margin: 1rem;"></div>
  <button (click)="getCallFlowDiagram()" id="callFlowDiagram" mat-raised-button color="primary" aria-label="Get Call Flow Diagram" class="button"
          [matTooltip]="'Generate Call Flow Diagram'+dynamicTip" matTooltipClass="tooltip">
    Get Call Flow Diagram</button>
</div>

<div class="flex-container-row">
  <div class="overview-label">Last Active</div>
  <div class="overview-input">
    <input type="text" [(ngModel)]="lastActive" class="overview-select" />
    <button mat-icon-button [matMenuTriggerFor]="lastActiveMenu" aria-label="Select an environment">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
  <button (click)="getStartTimesForAllEnvironments()" mat-raised-button color="primary" aria-label="Get All Start Times" class="button"
          matTooltip="Searches every environment in the environment dropdown list." matTooltipClass="tooltip">
    Get All Start Times</button>
  <div style="margin: 1rem;"></div>
  <button (click)="setCallFlowFilterVisibility()" mat-raised-button color="primary" aria-label="Toggle Call Flow Filters" class="button"
          matTooltip="Call Flow Filters" matTooltipClass="tooltip">Toggle Call Flow Filters
  </button>
</div>

<div *ngIf="isCFFvisible" class="flex-container-row">
  <div class="overview-label">Tracking ID</div>
  <div class="overview-input">
    <input type="text" [(ngModel)]="trackingID" [matMenuTriggerFor]="trackingIDBox" class="overview-select"/>
    <button style="visibility: hidden;" mat-icon-button aria-label="Select an environment">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
  <button style="visibility: hidden;">null</button>
  <div style="margin: 1rem;"></div>
  <button style="visibility: hidden;">null</button>
</div>

<div *ngIf="isCFFvisible" class="flex-container-row">
  <div class="overview-label">Beginning Time</div>
  <div class="overview-input">
    <input type="text" [(ngModel)]="beginningTime" [matMenuTriggerFor]="begTimeBox" class="overview-select"/>
    <button style="visibility: hidden;" mat-icon-button aria-label="Select an environment">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
  <button style="visibility: hidden;">null</button>
  <div style="margin: 1rem;"></div>
  <button style="visibility: hidden;">null</button>
</div>

<div *ngIf="isCFFvisible" class="flex-container-row">
  <div class="overview-label">Ending Time</div>
  <div class="overview-input">
    <input type="text" [(ngModel)]="endingTime" [matMenuTriggerFor]="endTimeBox" class="overview-select"/>
    <button style="visibility: hidden;" mat-icon-button aria-label="Select an environment">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
  <button style="visibility: hidden;">null</button>
  <div style="margin: 1rem;"></div>
  <button style="visibility: hidden;">null</button>
</div>
