<div class="flex-container">
  <div class="flex-container-row">
    <button mat-raised-button
            *ngFor="let group of this.filterGroups;"
            [color]="group.label === currentLabel ? 'accent' : 'primary'"
            (click)="showGroup(group.label, group.entries)">{{group.label}}</button>
    <button mat-raised-button color="primary" aria-label="toggle view mode"
            (click)="toggleView('textView', 'jsonViewer')">Toggle View</button>
  </div>
  <div id="json-container">
    <ngx-json-viewer id="jsonViewer" [json]="currentData" [expanded]="false"></ngx-json-viewer>
  </div>
  <pre id="textView" style="display: none;">{{ currentData | json }}</pre>
  <app-jstree-spinner></app-jstree-spinner>
</div>
