import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, from, pipe } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EnvironmentService } from '../environment.service';
import { UserService } from '../../users/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private isLoginSubject = new BehaviorSubject<boolean>(this.hasBearerToken());
  public isLoggedIn$ = this.isLoginSubject.asObservable();
  public logoutUrl;
  public idBrokerUrl;
  constructor(
    // private alertService: AlertService,
    private http: HttpClient,
    private router: Router, private userService: UserService, private dialog: MatDialog, private environment: EnvironmentService
  ) {
    this.logoutUrl = `https://${this.environment.getBrokerUrl()}/idb/oauth2/v1/logout`;
    // tslint:disable-next-line:max-line-length
    this.idBrokerUrl = `https://${this.environment.getBrokerUrl()}/idb/oauth2/v1/authorize?response_type=code&client_id=${this.environment.getClientId()}&scope=spark%3Apeople_read%20Identity%3ASCIM%20journal-tool%3Ajournal_read&redirect_uri=${encodeURIComponent(this.environment.getRedirectAuthUrl())}`;
  }

  public get isLoggedIn(): boolean {
    return this.hasBearerToken();
  }

  /**
   *  Log in user, push status to subscribers
   */
  public login(): void {
    const code = this.getAuthCode();
    const namespace = 'CHL';
    //follwing api from https://wiki.cisco.com/display/CIOAUTH/OAuth2+API+to+Get+Access+Token+by+Authorization+Code
    let headers = new HttpHeaders({
      'Authorization': 'Basic ' + btoa(this.environment.getClientId() + ':' + this.environment.getClientSecret()),
      'Content-Type': "application/x-www-form-urlencoded; charset=UTF-8",
    });

    let options = { headers: headers };
    const url = `https://${this.environment.getBrokerUrl()}/idb/oauth2/v1/access_token?grant_type=authorization_code&redirect_uri=${this.environment.getRedirectAuthUrl()}&code=${code}`;
    this.http.post(url, null, options).subscribe(
      (data: any) => {
        window.localStorage.setItem('access_token', data.access_token);
        window.localStorage.setItem('refresh_token', data.refresh_token);
        window.localStorage.setItem('access_token_expiration', (new Date().getTime() + parseInt(data.expires_in)*1000).toString());
        window.localStorage.setItem('refresh_token_expiration', (new Date().getTime() + parseInt(data.refresh_token_expires_in)*1000).toString());
        this.isLoginSubject.next(true);
        const redirectUrl = this.router.routerState &&
                            this.router.routerState.snapshot &&
                            this.router.routerState.snapshot.root &&
                            this.router.routerState.snapshot.root.queryParams["state"];
        console.log('login(): try to redirect to: ' + redirectUrl);
        this.router.navigateByUrl((redirectUrl && redirectUrl.startsWith('/')) ? redirectUrl : '/');
      },
      err => {
      });
  }

  /**
   * Logout users, push status to subscribers
   */
  public logout(redirect?): void {
    // Currently we use a form POST to call the CI /logout, on our end,
    // we have to clear our auth tokens from storage.
    window.localStorage.clear();

    if (redirect) {
      this.directToCommonIdentityLoginPage();
    }

    return this.isLoginSubject.next(false);
  }

  public hasBearerToken(): boolean {
    return !!localStorage.getItem('access_token');
  }

  public refreshAccessToken(): Observable<any> {
    console.log('in refresh access token');
    const refreshToken = window.localStorage.getItem('refresh_token');
    // following wiki from https://wiki.cisco.com/display/CIOAUTH/OAuth2+API+to+Refresh+Access+Token
    let headers = new HttpHeaders({
      'Authorization': 'Basic ' + btoa(this.environment.getClientId() + ':' + this.environment.getClientSecret()),
      'Content-Type': "application/x-www-form-urlencoded; charset=UTF-8",
      'Accept': "application/json; charset=UTF-8"
    });
    let options = { headers: headers };
    const url = `https://${this.environment.getBrokerUrl()}/idb/oauth2/v1/access_token?grant_type=refresh_token&refresh_token=` + refreshToken;
    return this.http.post(url, null, options).pipe(
      map((res: any) => {
        window.localStorage.setItem('access_token', res.access_token);
        window.localStorage.setItem('refresh_token', res.refresh_token);
        window.localStorage.setItem('access_token_expiration', (new Date().getTime() + parseInt(res.expires_in)*1000).toString());
        window.localStorage.setItem('refresh_token_expiration', (new Date().getTime() + parseInt(res.refresh_token_expires_in)*1000).toString());
        this.isLoginSubject.next(true);
        console.log('refreshAccessToken(): try to redirect to: ' + this.router.url);
        this.router.navigate([this.router.url]);
      }),
    );
  }


  public getAuthCode() {
    const query = window.location.search.substring(1); // TODO: Angular url params: Angular Router
    const params = query.split('&');
    for (const param of params) {
      const pair = param.split('=');
      if (decodeURIComponent(pair[0]) === 'code') {
        return decodeURIComponent(pair[1]);
      }
    }

    return undefined;
  }

  public directToCommonIdentityLoginPage() {
    window.open(
        this.idBrokerUrl +
        (this.router.url.startsWith("/login") ? '' : '&state=' + encodeURIComponent(this.router.url)),
        '_self'
        );
  }
}
