// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration=name` replaces `environment.ts` with the environment.name`.
// The list of file replacements can be found in `angular.json` configuration and serve sections.

export const environment = {
  production: true,


  devRedirectAuthUrl: 'http://127.0.0.1:8000',
  intRedirectAuthUrl: 'https://journaltool-int.webex.com',
  prodRedirectAuthUrl: 'https://journaltool.webex.com',
  fedRampRedirectAuthUrl: 'https://journaltool-usgov.webex.com',


  prodClientId: 'Cb4e99ede9a974023cab9087bb70d3c9babe0944360ce350af27d5badbc67f354',
  prodClientSecret: 'b768b516ecfe95ce38eb56ba76472e627a5c30d4a3a6100b4978c35c4d849dd0',
  intClientId: 'C73223c3220c178fb76874bf3725d31d0564f777dc3dda7d970ef64feae52e5be',
  intClientSecret: '7ed48f0b4e20ffca23de3e04492270a9986376a85f2b632060092a113022ead8',
  fedRampClientId: 'Cd5327cbda3c194819a6db31d76b73a6a6a7f34e80d5e58faff5427d26da9a353',
  fedRampClientSecret: '282e46dc920c5ac52deeca9645be0b6787c403a3455db97a6fc244acd6b469d6',


  devAtlasUrl: 'atlas-intb.ciscospark.com',
  intAtlasUrl: 'atlas-intb.ciscospark.com',
  prodAtlasUrl: 'atlas.ciscospark.com',


  devWebAuth: 'web-authentication-intb.ciscospark.com',
  intWebAuth: 'web-authentication-intb.ciscospark.com',
  prodWebAuth: 'web-authentication.ciscospark.com',


  devIdentityUrl: 'identitybts.webex.com',
  intIdentityUrl: 'identitybts.webex.com',
  prodIdentityUrl: 'identity.webex.com',
  fedRampIdentityUrl: 'identity-f.webex.com',


  devBrokerUrl: 'idbrokerbts.webex.com',
  intBrokerUrl: 'idbrokerbts.webex.com',
  prodBrokerUrl: 'idbroker.webex.com',
  fedRampBrokerUrl: 'idbroker-f.webex.com',


  //devJournalServiceUrl: 'http://localhost:8090/journal/journal',
  devJournalServiceUrl: 'https://journal-tool-intb.ciscospark.com/journal-tool',
  intJournalServiceUrl: 'https://journal-tool-intb.ciscospark.com/journal-tool',
  prodJournalServiceUrl: 'https://journal-tool-a.wbx2.com/journal-tool',
  fedRampJournalServiceUrl: 'https://journal-tool.gov.ciscospark.com/journal-tool'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
