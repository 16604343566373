import { KibanaConstants } from "../_constants/kibana-constants";
import { DataExtractionHelpers } from "./data-extraction";

/**
 * Utility which helps construct Kibana search URLs
 * See here for more information on the layout of a link: https://confluence-eng-gpk2.cisco.com/conf/pages/viewpage.action?spaceKey=LOCUS&title=Kibana+Integration+-+Design
 */
export class KibanaUtility {

  constructor() { }

  /**
   * Takes the given search parameters and creates then opens a Kibana search with the
   * desired search parameters
   *
   * @param env - The environment to open Kibana in (should be one KIBANA_PROD_HOST, KIBANA_INT_HOST)
   * @param startTime - The start time to begin the search (can be either absolute or relative)
   * @param endTime - The end time to end the search (can be either absolute or relative)
   * @param columns - A list of columns we want to display to the user in the search results
   * @param filters - An array of filters to apply to the results of the search. The format of each filter is:
   * [field_name, [values to match], should negate].
   * @param query - An additional string to place into the string search bar in Kibana
   */
   static constructKibanaSearchURL(env: string, startTime: string, endTime: string, columns: string[], filters: [string, string[], boolean][], query: string): string {
    let envLinkSubstring = KibanaUtility.formatKibanaEnvironment(env);
    let timeLinkSubstring = KibanaUtility.formatTimeString(startTime, endTime);
    let columnLinkSubstring = KibanaUtility.formatColumnSelections(columns);
    let filterLinkSubstring = KibanaUtility.formatFilters(filters);
    let indexLinkSubstring = KibanaUtility.formatIndex(env);
    let queryLinkSubstring = KibanaUtility.formatSearchQuery(query);
    return KibanaUtility.formatKibanaURL(envLinkSubstring, timeLinkSubstring, columnLinkSubstring, filterLinkSubstring, indexLinkSubstring, queryLinkSubstring);
  }

  /**
   * Takes the given meeting environment and returns the appropriate Kibana host
   * environment string.
   *
   * requires - env != null and env is a supported environment
   *
   * @param env - The environment of the locus id / meeting
   * @returns The environment to open
   */
  static getKibanaEnvironment(env: string): string {
    // TODO: incorporate fedramp (currently not supported)
    let meetingEnv = DataExtractionHelpers.getMeetingEnvironment(env);

    if (meetingEnv === DataExtractionHelpers.MEETING_ENV_PROD) {
      return KibanaConstants.KIBANA_PROD_HOST;
    } else if (meetingEnv === DataExtractionHelpers.MEETING_ENV_INT || meetingEnv === DataExtractionHelpers.MEETING_ENV_LOCAL || meetingEnv === DataExtractionHelpers.MEETING_ENV_LOADTEST) {
      return KibanaConstants.KIBANA_INT_HOST;
    }

    throw new ReferenceError(`env \'${env}\' is not supported in Kibana`);
  }

  // ---------- URL format helpers ---------- //

  /**
   * Takes the given environment and returns the appropriate Kibana host environment string.
   *
   * requires - env != null and env is a supported environment
   *
   * @param env - The environment to open Kibana in
   * @returns The environment to open
   */
   private static formatKibanaEnvironment(env: string): string {
    // TODO: incorporate fedramp (currently not supported)

    if (env === KibanaConstants.KIBANA_PROD_HOST) {
      return KibanaConstants.PRODUCTION_LOGS;
    } else if (env === KibanaConstants.KIBANA_INT_HOST) {
      return KibanaConstants.INTEGRATION_LOGS;
    }

    throw new ReferenceError(`env \'${env}\' is not supported in Kibana`);
  }

  /**
   * Takes the given start and end timestamps (can be either relative or absolute) as
   * strings and returns the Kibana query phrase which will return results within the range of
   * the supplied timestamps.
   *
   * requries - startTime, endTime != null
   *
   * @param startTime - The timestamp to start the search at in Kibana
   * @param endTime - The timestamp to end the search at in Kibana
   * @returns A formatted time query phrase
   */
  private static formatTimeString(startTime: string, endTime: string): string {
    if (startTime == null) {
      throw new ReferenceError(`startTime is null`);
    }

    if (endTime == null) {
      throw new ReferenceError(`endTime is null`);
    }

    // Wrapping with '' is necessary for absolute timestamps since they begin with a digit,
    // however we can also wrap relative timestamps and get the correct behavior.
    return `time:(from:\'${startTime}\',to:\'${endTime}\')`;
  }

  /**
   * Takes a list of columns to include in the results of the Kibana search and returns the formatted
   * column query phrase which will show those columns in Kibana.
   *
   * requires - columns != null
   *
   * @param columns - The list of columns we wish to include in the search results in Kibana.
   * @returns A formatted column query phrase
   */
  private static formatColumnSelections(columns: string[]): string {
    if (columns == null) {
      throw new ReferenceError(`columns is null`);
    }

    return 'columns:!(' + columns.join(',') + ')';
  }

  /**
   * Takes a list of filters to apply to a Kibana search and returns a formatted filter phrase which
   * will contain all of the supplied filters.
   *
   * requires - filters != null, and that for each filter request, the field and value lists are non-null
   *
   * @param filters - An array of filter requests. Each request contains the name of the field which the
   * filter should apply to, a list of values we want to match the given field against, and a flag for
   * whether to negate the phrase. If the list of strings is empty, then the filter that is created checks
   * only for the existance of the field. If the list of strings is non-empty, then the filter will require
   * the given field to match one of the supplied values.
   */
  private static formatFilters(filters: [string, string[], boolean][]): string {
    if (filters == null) {
      throw new ReferenceError(`filters is null`);
    }

    let filterElements = [];

    for (let i = 0; i < filters.length; i++) {
      if (filters[i][1] == null) {
        throw new ReferenceError(`filter element ${i} has a null value array`);
      }

      if (filters[i][1].length == 0) {
        filterElements.push(KibanaUtility.formatFilterFieldExists(filters[i][0], filters[i][2]));
      } else if (filters[i][1].length == 1) {
        filterElements.push(KibanaUtility.formatFilterFieldValueMatches(filters[i][0], filters[i][1][0], filters[i][2]));
      } else {
        filterElements.push(KibanaUtility.formatFilterFieldValueInList(filters[i][0], filters[i][1], filters[i][2]));
      }
    }

    return `filters:!(${filterElements.join(",")})`;
  }

  /**
   * Formats a filter phrase that will cause Kibana to return rows in which the given field has a
   * value that matches the given value. If negate is set to true, then rows whose field value does
   * not match the given value will be returned.
   *
   * requires - field != null, value != null
   *
   * @param field - The colum whose value we want to match against
   * @param value - The value we want the given field to match
   * @returns A filter phrase that will return rows which contain a field of the given value, or that
   * contain the given field but whose value does not match the given value if negate = true.
   */
  private static formatFilterFieldValueMatches(field: string, value: string, negate: boolean = false): string {
    if (field == null) {
      throw new ReferenceError(`field is null`);
    }

    if (value == null) {
      throw new ReferenceError(`value is null`);
    }

    let negateFlag = negate && 't' || 'f';
    return `(meta:(negate:!${negateFlag}),query:(${KibanaUtility.formatMatchPhrase(field, value)}))`;
  }

  /**
   * Formats a filter phrase that will cause Kibana to return rows in which the given field exists,
   * or if negate is set to true, then rows that don't have the given field.
   *
   * requires - field != null
   *
   * @param field - The column name we want to verify existance of
   * @param negate - If set to true, the filter will return rows that do not contain the field
   * @returns A filter phrase that will return rows containing the given field, or that do not
   * contain the field if negate = true.
   */
  private static formatFilterFieldExists(field: string, negate: boolean = false): string {
    if (field == null) {
      throw new ReferenceError(`field is null`);
    }

    let negateFlag = negate && 't' || 'f';
    return `(meta:(negate:!${negateFlag}),exists:(field:${field}))`;
  }

  /**
   * Formats a filter phrase that will cause Kibana to return rows in which the given field is one
   * of the values in the given values list. If negate is set to true, then Kibana will search for rows
   * whose field value is not one of the values in the given list.
   *
   * requires - field, values != null, and values to contain one or more non-null values
   *
   * @param field - The column whose value we want to match against the list
   * @param values - A list of values to match against the requested column
   * @param negate - If set to true, the filter phrase will return rows which do not match any of the values in the list
   * @returns A filter phrase that will return rows whose field matches atleast one of the values in the array,
   * or none in the array if negate = true
   */
  private static formatFilterFieldValueInList(field: string, values: string[], negate: boolean = false): string {
    if (field == null) {
      throw new ReferenceError(`field is null`);
    }

    if (values.length < 1) {
      throw new RangeError(`length of values is zero`);
    }

    if (values == null) {
      throw new ReferenceError(`values is null`);
    }

    let matchPhrases = [];

    for (let i = 0; i < values.length; i++) {
      matchPhrases.push('(' + KibanaUtility.formatMatchPhrase(field, values[i]) + ')');
    }

    let negateFlag = negate && 't' || 'f';
    return `(meta:(negate:!${negateFlag}),query:(bool:(minimum_should_match:1,should:!(${matchPhrases.join(',')}))))`;
  }

  /**
   * Formats a match phrase for the given key-value pair
   *
   * requires - field, value != null
   *
   * @param field - The column in the Kibana search we would like to match a value against
   * @param value - The value of the given column we would like to match
   * @returns A formatted match phrase
   */
  private static formatMatchPhrase(field: string, value: string): string {
    if (field == null) {
      throw new ReferenceError(`field is null`);
    }

    if (value == null) {
      throw new ReferenceError(`value is null`);
    }

    return `match_phrase:(${field}:\'${value}\')`;
  }

  /**
   * Takes and env string and returns one of two possible indexes for
   * prod and int kibana
   */
  private static formatIndex(env: string): string {

    if (env === KibanaConstants.KIBANA_PROD_HOST) {
      return KibanaConstants.PRODUCTION_INDEX;
    } else if (env === KibanaConstants.KIBANA_INT_HOST) {
      return KibanaConstants.INTEGRATION_INDEX;
    }

    throw new ReferenceError(`env \'${env}\' is not supported in Kibana`);
  }

  /**
   * Takes an additional value to include in the search bar of Kibana and returns the query
   * phrase to include the value in the Kibana search results.
   * If no value is desired, simply pass in the empty string ""
   *
   * requires - value != null
   *
   * @param value - The value to search for in Kibana
   * @returns A query phrase to include the value in the search bar of Kibana
   */
  private static formatSearchQuery(value: string): string {
    if (value == null) {
      throw new ReferenceError(`field is null`);
    }

    return `query:(language:lucene,query:\'${value}\')`;
  }

  /**
   * Takes formatted query phrases and uses them to construct the final link which can be used to make the search
   * in Kibana.
   *
   * requires - That the query phrases are non-null and properly formatted using the above formatters
   *
   * @param env - The environment host we would like to open Kibana in
   * @param time_range - The formatted time query phrase
   * @param columns - The formatted column query phrase
   * @param filters - The formatted filter query phrase
   * @param query  - The formatted language and search bar query phrase
   * @returns The link which will make the desired search in Kibana
   */
  private static formatKibanaURL(env: string, time_range: string, columns: string, filters: string, index: string, query: string): string {
    if (env == null) {
      throw new ReferenceError(`env is null`);
    }

    if (time_range == null) {
      throw new ReferenceError(`time_range is null`);
    }

    if (columns == null) {
      throw new ReferenceError(`columns is null`);
    }

    if (filters == null) {
      throw new ReferenceError(`filters is null`);
    }

    if (query == null) {
      throw new ReferenceError(`query is null`);
    }

    return `https://${env}/app/discover#/?_g=(${time_range})&_a=(${columns},${filters},${index},${query})`;
  }
}
