import {Component, HostListener, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DialogBoxComponent} from '../dialog-box/dialog-box.component';
import {EnvironmentService} from '../_services/environment.service';
import {JournalToolBackendRequestsService} from '../_services/journal-tool-backend-requests.service';
import {DataExtractionHelpers} from '../_utilities/data-extraction';
import {CellKeyDownEvent, GridApi, GridReadyEvent} from 'ag-grid-community';
import {CustomTooltip} from './CustomTooltip';
import {KibanaUtility} from '../_utilities/kibana-utils';
import {KibanaConstants} from '../_constants/kibana-constants';
import {JournalJumpRendererComponent} from '../journal-jump-button/journal-jump-button.component';
import {JournalConstants} from '../_constants/journal-constants';
import {UiUtils} from '../_utilities/ui-utils';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import {Observable} from "rxjs";
import {ThemeService} from "../_services/theme.service";

/** Custom options to configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 0,
  touchendHideDelay: 0,
};

let lastCellClicked =  "null";

function copyToClipBoard(s) {
  let textArea = document.createElement("textarea");
  textArea.value = s;
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
}

@Component({
  selector: 'app-walk-journal',
  templateUrl: './walk-journal.component.html',
  styleUrls: ['./walk-journal.component.scss'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}  ]
})

export class WalkJournalComponent implements OnInit {

  data = null;
  lastRowClicked = 1;  // 1-indexed
  timestampClicked = false;

  env = '';
  locusId = '';
  startTime = '';
  journalVersion = '';
  searchString = '';
  j = ''
  keepTransactionsCheckBox = true;
  joinedParticipantsCount = 0;
  connectedDevicesCount = 0;
  locusState = 'INITIALIZING';
  trackingID = '';
  beginningTime = '';
  endingTime = '';

  //for scrolling to a timestamp
  scrollTimesArray: { }[] = []; //the array of timestamps that a user can scroll to
  preLoadScrollTimeString = "Please wait for the data to load...";
  scrollTimeString = this.preLoadScrollTimeString;
  showScrollTimeErrorMessage = false;
  bottomRowIndex: number;
  DEFAULT_BOTTOM_ROW_INDEX = -1;
  jumpFormEnabled: boolean = false; //false by default until the data has loaded in
  isLiveMeeting: boolean = false; // false by default
  timestampWithMillisecondsLength = 23; //length of string YYYY-MM-DDThh-mm-ss:ttt
  timestampWithoutMillisecondsLength = 19; //length of string YYYY-MM-DDThh-mm-ss

  kibanaDropDownOptions = KibanaConstants.MENU_OPTIONS;

  // Instance variable used to disable kibana button if the environment is fedramp TODO: remove this when fedramp is implemented
  kibanaEnabled: boolean = false;

  //for maintaining current scroll upon search
  journalRowViewportSize = 20;

  // For jumping to/from breakout journals
  // Example use: this.data['tableData'][rowIndex][this.TIMESTAMP_INDEX];
  TIMESTAMP_INDEX = 2;
  jumpTable = new Map();

  // For grid key navigation
  priorSearchString = '';
  rowClickHistory = [];
  KEY_DOWN = 'ArrowDown';
  KEY_UP = 'ArrowUp';
  // A map consisting of all users in the grid and the first row of each transaction started by them
  userTransactionMap = new Map<string, any[]>([]);

  // breakouts: used to drive breakout summary button. breakoutHidden value must be 'hidden' to hide the button (default)
  breakoutHidden = 'hidden';
  breakoutId = '';

  // theme stuff
  theme$!: Observable<string>;
  agTheme$!: Observable<string>;

  PARTICIPANT_COL_NAMES = [
    'User',
    'User Type',
    'User Roles',
    'User State',
    'Device',
    'User Agent',
    'Device State',
    'Device Intent',
    'Device Audio',
    'Device Video',
    'Device Slides'
  ];

  PARTICIPANT_INDEX = 0;
  USER_TYPE_INDEX = 1;
  USER_ROLES_INDEX = 2;
  USER_STATE_INDEX = 3;
  DEVICE_NAME_INDEX = 4;
  USER_AGENT_INDEX = 5;
  DEVICE_STATE_INDEX = 6;
  DEVICE_INTENT_INDEX = 7;
  DEVICE_AUDIO_INDEX = 8;
  DEVICE_VIDEO_INDEX = 9;
  DEVICE_SLIDES_INDEX = 10;

  // AG Grid vars
  userRowsGridOptions = {
    rowSelection: 'single',
    defaultColDef: {
      resizable: true,
      sortable: false,
      cellStyle: function (params) {
        if (params.value == 'JOINED' || params.value == 'JOIN') {
          return {color: 'green'};
        } else if (params.value == 'LEAVE' || params.value == 'LEFT') {
          return {color: 'red'};
        } else if (params.value == 'RESET_SUBSCRIBERS') {
          return {color: 'blue'};
        } else {
          return null;
        }
      }
    },
    suppressHeaderFocus: true
  } as any;

  journalRowsGridOptions = {
    rowSelection: 'single',
    components: {
      buttonRenderer: JournalJumpRendererComponent
    },
    onCellClicked: function (event) {
      let columnHeader=event['colDef'].headerName;
      this.timestampClicked = (columnHeader === JournalConstants.COLUMN_TIMESTAMP);
      lastCellClicked = event['value'];
    }.bind(this),
    onCellKeyDown: function(e: CellKeyDownEvent) {
      this.keyNavigation(e);
    }.bind(this),
    onRowClicked: function (event) {
      // row number that is displayed, 1-indexed
      this.lastRowClicked = Number(this.journalRowsGridApi.getSelectedRows()[0]['Row']);
      this.updateUserGrid(this.lastRowClicked - 1).then({});
      this.joinedParticipantsCount = this.getJoinedParticipantCount(this.lastRowClicked - 1);
      this.connectedDevicesCount = this.getConnectedDeviceCount(this.lastRowClicked - 1);
      this.locusState = this.data['response']['rowList'][this.lastRowClicked - 1]['locusState'];
      this.rowClickHistory.unshift(this.journalRowsGridApi.getSelectedNodes()[0].id);
    }.bind(this),
    onRowDoubleClicked: function (event) {
      this.showAttrs();
    }.bind(this),
    defaultColDef: {
      resizable: true,
      sortable: false,
      tooltipComponent: CustomTooltip,
      cellStyle: function (params) {
        if (params.value == 'JOINED' || params.value == 'JOIN') {
          return {color: 'green'};
        } else if (params.value == 'LEAVE' || params.value == 'LEFT') {
          return {color: 'red'};
        } else if (params.value == 'RESET_SUBSCRIBERS') {
          return {color: 'blue'};
        } else {
          return null;
        }
      }
    }
  } as any;

  clearCache: boolean = false;
  // id's of buttons for which we want to toggle the color when cache clearing is enabled
  cacheButtons = ['callFlowDiagram'];
  dynamicTip = '';

  attrsList = [];

  private userRowsGridApi!: GridApi;
  onUserRowsGridReady = (event: GridReadyEvent) => {
    // Store the api for later use
    this.userRowsGridApi = event.api;
  }
  private journalRowsGridApi!: GridApi;
  onJournalRowsGridReady = (event: GridReadyEvent) => {
    // Store the api for later use
    this.journalRowsGridApi = event.api;
  }

  constructor(private http: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private environment: EnvironmentService,
              private themeService: ThemeService,
              private backendService: JournalToolBackendRequestsService) {
    if (environment.getCurrentEvironment() !== environment.FEDRAMP && !environment.isEnvControlledByConfig()) {
      // TODO - remove this, the instance variable, and the check in the menu component when Fedramp is implemented for Kibana
      this.kibanaEnabled = true;
    }
  }

  // enables/disables cache clearing when Ctrl-Delete is typed
  @HostListener('document:keyup.control.delete', ['$event'])
  onKeyup() {
    this.toggleClearCache();
    this.adjustCacheButtons();
  }

  toggleClearCache() {
    this.clearCache = !this.clearCache;
  }

  adjustCacheButtons() {
    this.cacheButtons.forEach((buttonId) => {
      let button = document.getElementById(buttonId);
      if (button !== null) {
        if (this.clearCache === true) {
          button.classList.add('md-button--red');
        } else {
          button.classList.remove('md-button--red');
        }
      }
    });
    this.dynamicTip = (this.clearCache === true ? ' from source data' : '');
  }

  async ngOnInit() {

    UiUtils.setWaitCursor();

    // initialize from query params
    this.env = this.route.snapshot.queryParams['environment'];
    this.locusId = this.route.snapshot.queryParams['locusID'];
    this.startTime = this.route.snapshot.queryParams['date'];
    this.trackingID = this.route.snapshot.queryParams['trackingID'];
    this.beginningTime = this.route.snapshot.queryParams['beginningTime'];
    this.endingTime = this.route.snapshot.queryParams['endingTime'];

    this.clearCache = this.route.snapshot.queryParams['clearCache'] === "true";
    if (this.clearCache === null) {
      this.clearCache = false;
    }

    this.isLiveMeeting = window.location.href.search(/\bdate=Live\b/) != -1;

    document.title = `Walk Journal : ${this.locusId} @ ${this.startTime}`;

    // theme stuff
    this.theme$ = this.themeService.getTheme();
    this.agTheme$ = this.themeService.getAgGridTheme();

    if (!window['data']) {
      let chunkNum = 0;
      let data = "";
      let initialized: boolean = false;
      let tmpClear: boolean = this.clearCache;

      while (true) {
        let request = await this.backendService.getJournalChunk(
          this.route.snapshot.queryParams['environment'],
          this.route.snapshot.queryParams['locusID'],
          this.route.snapshot.queryParams['date'],
          chunkNum,
          tmpClear);

        //if backend gives an error
        if (request['status'] === this.backendService.FAILURE) {
          this.dialog.open(DialogBoxComponent, {
            width: '500px',
            data: { message: request['errorMessage'] }
          });
          break;
        }

        // make sure we only clear on the first call
        tmpClear = false;

        // set or combine data
        data = this.backendService.combineResponses(data, request['data']);

        this.data = data;

        if (this.data !== null) {
          this.loadData(initialized);
          initialized = true;
        } else if (request['status'] !== this.backendService.DONE) {
          // no data received, and no DONE indicator
          this.dialog.open(DialogBoxComponent, {
            width: '500px',
            data: {message: 'Cannot open journal: no data received'}
          });
          break;
        }

        if (request['status'] === this.backendService.DONE) {
          //if we have received a DONE from backend, there is no more information, so exit the while loop
          break;
        }

        chunkNum += 1;
      }

    } else {
      this.data = window['data'];
      this.loadData(false);
    }

    if (this.data !== null) {
      //once all data is loaded, find the scroll times that can be jumped to and enable the forms
      this.findScrollTimes(this.data['tableData']);
      this.initialAutoScroll();
      this.jumpFormEnabled = true;
      this.filterByUserTransactionMap();
      this.postProcessForBreakoutSummary();

      for (let row of this.data['response']['rowList']) {
        this.attrsList.push(row['attrs']);
      }
    } else {
      this.userRowsGridApi.showNoRowsOverlay();
      this.journalRowsGridApi.showNoRowsOverlay();
    }

    UiUtils.setDefaultCursor();
  }

  ngAfterViewInit() {
    this.adjustCacheButtons();
  }


  private loadData(initialized: boolean) {
    if (this.data !== null) {
      //load the necessary information
      this.locusId = this.data['response']['locusId'];
      this.startTime = this.data['response'].activeTime;

      if (this.data['response'].journalVersion == "1.0") {
        this.journalVersion = "1.0 (Multi-Writer)"
      } else if (this.data['response'].journalVersion == "2.0") {
        this.journalVersion = "2.0 (Single-Writer)"
      }
      this.joinedParticipantsCount = this.getJoinedParticipantCount(0);
      this.connectedDevicesCount = this.getConnectedDeviceCount(0);
      this.locusState = this.data['response']['rowList'][this.data['response']['rowList'].length - 1]['locusState'];

      //update the user rows and journal rows
      this.populateUserRows(initialized);
      // Note: populate_journal_jump_table() uses scroll times to populate the jumpTime in the URLs
      this.findScrollTimes(this.data['tableData']);
      this.populateJournalJumpTable();
      this.populateJournalRowsTable(initialized);
    }
  }

  populatePlotlyTraces() {
    let traces = [];
    let times = [];
    let y0 = [];
    let y1 = [];
    let y2 = [];
    for (let row of this.scrollTimesArray) {
      let idx = row['rowIndex'];
      let joined = this.getJoinedParticipantCount(idx);
      let connected = this.getConnectedDeviceCount(idx);
      let lobby = this.getLobbyDeviceCount(idx);
      let ts = row['timestamp'];
      times.push(ts);
      y0.push(joined);
      y1.push(connected);
      y2.push(lobby);
    }

    let trace0 = {
      x: times,
      y: y0,
      type: "scatter",
      name: "Joined Users"
    };
    traces.push(trace0);

    let trace1 = {
      x: times,
      y: y1,
      type: "scatter",
      name: "Connected Devices"
    };
    traces.push(trace1);

    let trace2 = {
      x: times,
      y: y2,
      type: "scatter",
      name: "Lobby Devices"
    };
    traces.push(trace2);
    window['traces'] = traces;
  }

  getParticipantList(row) {
    let originalRow = 0;
    let val = Number(row);

    while (val >= 0) {
      if (this.data['tableData'][val][2].length > 0) {//this while loop sets original row to be the closest one before the given row number that has a timestamo
        originalRow = this.data['tableData'][val][0];
        break;
      }
      val--;
    }
    return this.data['response']['participantDtoListPerTransaction'][originalRow];
  }

  getJoinedParticipantCount(row) {
    let count = 0;
    let participants = this.getParticipantList(row);
    let participantDtoMap = this.data['response']['participantDtoMap'];
    for (let p of participants) {
      if ("JOINED" == participantDtoMap[p]['state']) {
        count++;
      }
    }
    return count;
  }

  getConnectedDeviceCount(row) {
    let count = 0;
    let participants = this.getParticipantList(row);
    let participantDtoMap = this.data['response']['participantDtoMap'];
    for (let p of participants) {
      for (let d of participantDtoMap[p]['deviceList']) {
        if ("JOINED" == d['state']) {
          count++;
        }
      }
    }
    return count;
  }

  getLobbyDeviceCount(row) {
    let count = 0;
    let participants = this.getParticipantList(row);
    let participantDtoMap = this.data['response']['participantDtoMap'];
    for (let p of participants) {
      for (let d of participantDtoMap[p]['deviceList']) {
        if ("IDLE" == d['state'] && "WAIT" == d['intent']) {
          count++;
        }
      }
    }
    return count;
  }

  populateJournalJumpTable() {
    const journalJumpIndex = this.data['COL_NAMES'].length;
    const deviceEventIndex = DataExtractionHelpers.findColumnIndexFromName(this.data['COL_NAMES'], 'Device Event');
    if (deviceEventIndex === -1) {
      console.error('populateJournalJumpTable(): deviceEventIndex not found.');
      return;
    }

    // Add a column name for journal jump
    this.data['COL_NAMES'][journalJumpIndex] = JournalConstants.COLUMN_JUMP;

    // For each row in our journal rows
    for (let rowIndex = 0; rowIndex < this.data['tableData'].length; rowIndex++) {
      const deviceEvent = this.data['tableData'][rowIndex][deviceEventIndex];
      if (deviceEvent === JournalConstants.EVENT_REPLACED_BY || deviceEvent === JournalConstants.EVENT_REPLACES) {
        const attrs = JSON.parse(this.data['response']['rowList'][rowIndex]['attrs'])['attrs'];
        // Get the locusURL
        const locusUrl = attrs['locusUrl'];

        if (locusUrl) {
          // Populate the cell value with our jj signifying string
          this.data['tableData'][rowIndex][journalJumpIndex] = JournalConstants.COLUMN_JUMP;
          // Example locusURL format: "https://locus-a.wbx2.com/locus/api/v1/loci/a5efaaf8-dfb5-49c8-968d-6c123499f7e3"
          const locusId = locusUrl.substring(locusUrl.lastIndexOf('/') + 1);
          const date = attrs['lastActive'];

          let initialJumpTime = this.data['tableData'][rowIndex][this.TIMESTAMP_INDEX];

          if (!initialJumpTime) {
            initialJumpTime = this.findTransactionTimeStamp(rowIndex);
          }

          const url = this.router.createUrlTree(['/walkJournal'], {
            queryParams: {
              'locusID': locusId,
              // Note: utilizing source locus environment, not destination... known to be equal
              'environment': this.env,
              'date': date,
              'trackingID': this.trackingID,
              'beginningTime': this.beginningTime,
              'endingTime': this.endingTime,
              'jumpTime': initialJumpTime
            }
          });
          // Store the (rowIndex, url) pair
          this.jumpTable.set(rowIndex, url);
        }
      }
    }
  }

  /*
  Returns the timestamp associated with the row closest to rowIndex such that row < rowIndex and scrollTimesArray[row] is defined,
  undefined if there is no such row
   */
  findTransactionTimeStamp(rowIndex) {
    const index = this.findScrollTimesTargetIndex(rowIndex, "rowIndex");
    // Return the timestamp at the determined index... might be undefined
    if (this.scrollTimesArray[index]) {
      return this.scrollTimesArray[index]["timestamp"];
    } else {
      console.warn('findInitialJumpTime(): alert: this.scrollTimesArray[index] is invalid');
      return undefined;
    }
  }

  /**
   * Utility function to search scrollTimesArray. Used by findTransactionTimeStamp() and scrollToTimestamp().
   *
   * @param targetValue Either a targetTimestamp: string, or targetRowIndex: number
   * @param fieldToMatch Either "timestamp", or "rowIndex". Used to access fields in scrollTimesArray object
   * of type {"timestamp": string, "rowIndex": number}. Note: dependent on input for targetValue and vice versa.
   * @returns Either the index associated with the timestamp closest to targetValue such that
   * timestamp <= targetValue, OR the row closest to targetValue such that row < targetValue, depending on parameter pair. -1 if intended
   * value is not found.
   */
  findScrollTimesTargetIndex(targetValue, fieldToMatch) {
    // For each index in the scrollTimesArray
    for (let index = 0; index < this.scrollTimesArray.length; index++) {
      // If the targetValue is a targetRowIndex && we've surpassed it
      if ((typeof targetValue) === "number" && (this.scrollTimesArray[index][fieldToMatch] > targetValue)) {
        // Go back one index value and return
        return index - 1;
      // Else if the targetValue is a targetTimestamp
      } else if ((typeof targetValue) === "string") {
        const compareVal = targetValue.localeCompare(this.scrollTimesArray[index][fieldToMatch]);
        // If we've found the targetTimeStamp
        if (compareVal === 0) {
          return index;
        // Else if the timestamp occurs after targetTimestamp, either go back one index value and return, or return 0, i.e.,
        // the targetTimestamp occurs earlier than any timestamp in the scrollTimesArray
        } else if (compareVal < 0) {
          return (index - 1 < 0) ? 0 : index - 1;
        }
      }
    }
    return this.scrollTimesArray.length - 1;
  }

  journalJump(params) {
    const urlToOpen = this.jumpTable.get(Number(params.rowData.Row-1));
    if (urlToOpen) {
      window.open(urlToOpen.toString(), '_blank');
    } else {
      console.error('populateJournalRowsTable onCellClicked(): urlToOpen is invalid.');
    }
  }

  // populate the main journal view table
  populateJournalRowsTable(isInitialized) {

    // process column header definition
    let col_names = this.createIndexToTitleMap(this.data['COL_NAMES']);
    let journalColumnTitlesForGrid = [];

    for (let colDef of this.data['COL_NAMES']) {
      if (String(colDef) === JournalConstants.COLUMN_JUMP) {
        journalColumnTitlesForGrid.push({
          headerName: String(colDef),
          field: String(colDef),
          cellRenderer: 'buttonRenderer',
          cellRendererParams: {
            onClick: this.journalJump.bind(this)
          },
          tooltipField: JournalConstants.COLUMN_JUMP,
          tooltipComponentParams: {color: '#ececec'}
        });
      } else if (String(colDef) === JournalConstants.COLUMN_DEVICE_EVENT) {
        journalColumnTitlesForGrid.push({
          headerName: String(colDef),
          field: String(colDef),
          minWidth: '300',
          tooltipComponentParams: {color: '#ececec'}
        });
      } else if (String(colDef) === JournalConstants.COLUMN_TIMESTAMP) {
        journalColumnTitlesForGrid.push({
          headerName: String(colDef),
          field: String(colDef),
          minWidth: '200',
          tooltipComponentParams: {color: '#ececec'}
        });
      } else {
        journalColumnTitlesForGrid.push({headerName: String(colDef), field: String(colDef), minWidth: '70',})
      }
    }

    let currentScrollIndex = this.findCurrentScrollIndex();

    let journalRowsForGrid = this.createAgGridDict(this.data['tableData'], col_names);

    if (isInitialized === false) {
      //if not initialized, then set up the window
      this.journalRowsGridApi.setGridOption('columnDefs', journalColumnTitlesForGrid);
      this.journalRowsGridApi.setGridOption('rowData', journalRowsForGrid);
    } else {
      //if initialized, simply update the data
      this.journalRowsGridApi.setGridOption('rowData', journalRowsForGrid);
    }
    this.bottomRowIndex = this.data['tableData'].length - 1;

    this.journalRowsGridApi.ensureIndexVisible(Number(currentScrollIndex), "top");
    this.journalRowsGridApi.sizeColumnsToFit();
  }

  // extract data by participant id from DTO
  getParticipantDataFromDTO(getDTOData) {
    if (Object.keys(getDTOData).length > 0 && getDTOData["locus"] && getDTOData["locus"]["participants"] && getDTOData["locus"]["participants"].length > 0) {
      let participantData = {}
      for (let participant of getDTOData["locus"]["participants"]) {
        participantData[participant["id"]] = {};
        participantData[participant["id"]]["externalIdType"] = participant["person"] && participant["person"]["externalIdType"];

        if (participant["controls"] && participant["controls"]["role"] && participant["controls"]["role"]["roles"] && participant["controls"]["role"]['roles'].length > 0) {
          let participantRoles = participant["controls"]["role"]['roles'];
          let participantControlsList = [];
          for (let role of participantRoles) {
            if (role["type"] && role["hasRole"]) {
              let roleName = role["type"].toLowerCase();
              participantControlsList.push(roleName.charAt(0).toUpperCase() + roleName.slice(1)) //title case
            }
          }
          participantData[participant["id"]]["roles"] = participantControlsList.join(", ");
        }
      }
      return participantData;
    }
    return {}
  }

  //after getParticipantDataFromDTO() returns, creates participant table and then sets row data
  //if promise errors, sets row data in old way (from this.data['response']['participantDtoMap']
  async updateUserGrid(rowIndex) {
    let getDTOData : object = await this.backendService.getDTO(this.route.snapshot.queryParams['environment'], this.route.snapshot.queryParams['locusID'], this.route.snapshot.queryParams['date'], this.lastRowClicked)

    let participantData = this.getParticipantDataFromDTO(getDTOData);
    this.userRowsGridApi.setGridOption('rowData', this.createNewParticipantTable(rowIndex, participantData));
    let userRowIndex = this.getUserIndexForJournalRowIndex(rowIndex);
    if (userRowIndex > -1) {
      this.userRowsGridApi.ensureIndexVisible(userRowIndex, 'middle');
      this.userRowsGridApi.forEachNode(node => node.rowIndex === userRowIndex ? node.setSelected(true) : -1);
    }
  }

  resizeJournalAndUserGrid() {
    this.journalRowsGridApi.sizeColumnsToFit();
    this.userRowsGridApi.sizeColumnsToFit();
  }

  //returns new 2d array of strings corresponding to user participant map at the given row.
  createNewParticipantTable(row, participantDataFromDto: {}) {
    let participants = this.getParticipantList(row);
    let participantDtoMap = this.data['response']['participantDtoMap'];

    let count = 0;
    for (let p of participants) {
      let deviceList = participantDtoMap[p]['deviceList'];
      if (deviceList.length == 0) {
        count += 1;
      } else {
        count += deviceList.length;
      }
    }

    let result = new Array(count);

    for (let tmp = 0; tmp < count; tmp++) {
      result[tmp] = new Array(this.PARTICIPANT_COL_NAMES.length);
    }

    //sort participants by its name and pid
    //  participants.sort(Comparator.comparing(p -> (userMap.get(p.getUserId()) + p.getUserId())));
    participants.sort(
      function (a, b) {
        a = this.data['userMap'][participantDtoMap[a]['userId']] + participantDtoMap[a]['deviceList'];
        b = this.data['userMap'][participantDtoMap[b]['userId']] + participantDtoMap[b]['deviceList'];
        return a > b ? 1 : b > a ? -1 : 0;
      }.bind(this)
    );

    let i = 0;

    for (let p of participants) {
      let userName = this.data['userMap'][participantDtoMap[p]['userId']];
      //response.getTestUserNameMap().get(p.getUserId());
      let testUserName = this.data['response']['testUserNameMap'][participantDtoMap[p]['userId']];
      let currentParticipantDataFromDTO = participantDataFromDto && Object.keys(participantDataFromDto).length > 0 &&
                                            participantDataFromDto[participantDtoMap[p]['userId']];

      if (userName != null) {
        result[i][this.PARTICIPANT_INDEX] = userName;
        let doesExternalIdMatchUserId = false;
        for (let participantInfo of this.data['response']['participantInfoList']) {
          if (participantInfo['userId'] == participantDtoMap[p]['userId']) {
            if (participantDtoMap[p]['userId'] == participantInfo['userOrExternalId']) {
              doesExternalIdMatchUserId = true;
            }
          }
        }
        if (!doesExternalIdMatchUserId) {
          result[i][this.USER_TYPE_INDEX] = "CI USER";
        } else {
          result[i][this.USER_TYPE_INDEX] = "NON CI USER";
          let externalIdType = currentParticipantDataFromDTO && currentParticipantDataFromDTO["externalIdType"];
          if (externalIdType != null) {
            if (externalIdType === "CI_ANONYMOUS_TOKEN") {
              result[i][this.USER_TYPE_INDEX] = "CI ANONYMOUS USER";
            } else if (externalIdType === "GUEST_TOKEN") {
              result[i][this.USER_TYPE_INDEX] = "UNVERIFIED GUEST USER";
            }
          }
        }
      } else if (testUserName != null) {
        result[i][this.PARTICIPANT_INDEX] = testUserName;
        result[i][this.USER_TYPE_INDEX] = "TEST";
      }

      result[i][this.USER_STATE_INDEX] = participantDtoMap[p]['state'];

      let userRoles = '';

      if (participantDtoMap[p]['isModerator']) {
        userRoles = "Moderator"
      }

      if (participantDtoMap[p]['isCoHost']) {
        userRoles = userRoles == '' ? "Cohost" : userRoles + ";Cohost";
      }

      if (participantDtoMap[p]['isPresenter']) {
        userRoles = userRoles == '' ? "Presenter" : userRoles + ";Presenter";
      }

      if (currentParticipantDataFromDTO) {
        userRoles = currentParticipantDataFromDTO["roles"];
      }

      result[i][this.USER_ROLES_INDEX] = userRoles;

      let deviceList = participantDtoMap[p]['deviceList'];
      if (deviceList.length > 0) {
        for (let device of deviceList) {
          let deviceName = this.data['deviceNameMap'][device['deviceUrl']];
          let intent = '';
          if (device['intent'] !== undefined) {
            //getAssociatedWithName(device.getAssociatedWith()); write this later???
            let associatedWithName = this.data['userMap'][device['associatedWith']];
            if (associatedWithName && deviceName != associatedWithName) {
              intent = device['intent'] + " with " + associatedWithName;
            }
          }
          let audio = '';
          let video = '';
          let slides = '';
          for (let mediaDto of device['mediaDtoList']) {
            if (mediaDto['type'].toLowerCase() === 'audio' && mediaDto['content'].toLowerCase() === 'main') {
              audio = mediaDto['direction'] + '/' + mediaDto['state'];
            } else if (mediaDto['type'].toLowerCase() === 'video' && mediaDto['content'].toLowerCase() === 'main') {
              video = mediaDto['direction'] + '/' + mediaDto['state'];
            } else if (mediaDto['type'].toLowerCase() === 'video' && mediaDto['content'].toLowerCase() === 'slides') {
              slides = mediaDto['direction'] + '/' + mediaDto['state'];
            }
          }

          let userAgentType = "";
          for (let deviceInfo of this.data['response']['deviceInfoList']) {
            if (deviceInfo['deviceUrl'] === device['deviceUrl']) {
              userAgentType = deviceInfo['userAgentType'];
              if (deviceInfo['deviceType'] === 'PROVISIONAL' && deviceInfo['deviceUrl'].includes('dialout') && !userAgentType.includes('Poros')){
                userAgentType = userAgentType + '/Poros'
              }
              break;
            }
          }
          result[i][this.DEVICE_NAME_INDEX] = deviceName;
          result[i][this.USER_AGENT_INDEX] = userAgentType;
          result[i][this.DEVICE_STATE_INDEX] = device['state'];//device.getState();
          result[i][this.DEVICE_INTENT_INDEX] = intent;
          result[i][this.DEVICE_AUDIO_INDEX] = audio;
          result[i][this.DEVICE_VIDEO_INDEX] = video;
          result[i][this.DEVICE_SLIDES_INDEX] = slides;
          i++;
        }
      } else {
        result[i][this.DEVICE_INTENT_INDEX] = participantDtoMap[p]['intent'];
        i++;
      }
    }
    let col_names = this.createIndexToTitleMap(this.PARTICIPANT_COL_NAMES);
    return this.createAgGridDict(result, col_names);
  }

  createAgGridDict(tableOfStrings, colNames) {
    let dict = []

    for (let row of tableOfStrings) {
      let row_to_add = {};
      let i = 0;
      for (let s of row) {
        if (s == null) {
          s = "";
        }
        row_to_add[String(colNames[i])] = String(s);
        i += 1;
      }

      dict.push(row_to_add);
    }
    return dict;
  }

  // Uses dataRows to find the relative table row of each timestamp, then stores those into this.scrollTimesArray
  findScrollTimes(dataRows) {
    this.scrollTimesArray = [];
    this.bottomRowIndex = this.DEFAULT_BOTTOM_ROW_INDEX;
    if (dataRows) {
      let rowIndex = 0;
      for (let row of dataRows) {
        if (row) {
          if (row[this.TIMESTAMP_INDEX]) { //if this row has a timestamp, store it as a jump-able point
            this.scrollTimesArray.push({
              "timestamp": String(row[2]).substring(0, this.timestampWithMillisecondsLength),
              "rowIndex": rowIndex
            });
          }
        } else {
          console.error('findScrollTimes(): alert: row invalid');
        }
        // The next rowIndex... if there is another row
        rowIndex += 1;
      }
      this.bottomRowIndex = rowIndex - 1;
    } else {
      console.error('findScrollTimes(): alert: dataRows invalid');
    }
  }

  /*
  The first time the table rows load, we auto-populate the timestamp search with either the first one, or the internally specified one,
  auto-scrolling accordingly.
   */
  initialAutoScroll() {
    // Autofill input with the first timestamp, if one exists. Only update the scrollTimeString the first time the table rows load
    if (this.scrollTimesArray[0]) {
      if (this.scrollTimesArray[0]["timestamp"] && this.scrollTimeString === this.preLoadScrollTimeString) {
        // Get the initial jump time specified in the query params
        const initialJumpTime = this.route.snapshot.queryParams['jumpTime'];
        // If it was specified
        if (initialJumpTime) {
          // Set up the relevant vars, and scroll to it
          this.scrollTimeString = initialJumpTime.substring(0, this.timestampWithMillisecondsLength);
          this.scrollFunction();
        } else {
          this.scrollTimeString = this.scrollTimesArray[0]["timestamp"].substring(0, this.timestampWithoutMillisecondsLength);
        }
      } else {
        console.warn('alert: initialAutoScroll(): unexpected value for this.scrollTimesArray[0]["timestamp"]: '
          + this.scrollTimesArray[0]["timestamp"] + ' OR this.scrollTimeString: ' + this.scrollTimeString);
      }
    } else {
      console.warn('alert: initialAutoScroll(): unexpected value for this.scrollTimesArray[0]: ' + this.scrollTimesArray[0]);
    }
  }

  populateUserRows(initialized: boolean) {

    let userMapRowsForGrid = this.createNewParticipantTable(0, {});

    if (!initialized) {
      // if not initialized, then set up the window
      // let the grid know which columns to use
      let userMapColumnTitlesForGrid = [];
      for (let colDef of this.PARTICIPANT_COL_NAMES) {
        userMapColumnTitlesForGrid.push({headerName: String(colDef), field: String(colDef), minWidth: '70'});
      }
      this.userRowsGridApi.setGridOption('columnDefs', userMapColumnTitlesForGrid);
    }

    // update the data
    this.userRowsGridApi.setGridOption('rowData', userMapRowsForGrid);

    this.userRowsGridApi.sizeColumnsToFit();
  }

  createIndexToTitleMap(columnNameList) {
    let col_names = {};
    let i = 0;//simple map from column index to name and vice versa
    for (let colName of columnNameList) {
      col_names[colName] = i;
      col_names[i] = colName.toString();
      i += 1;
    }
    return col_names;
  }

  showLegend() {
    let url = this.router.createUrlTree(['/showLegend'], {
      queryParams: {
        'locusID': this.route.snapshot.queryParams['locusID'],
        'environment': this.route.snapshot.queryParams['environment'],
        'date': this.route.snapshot.queryParams['date']
      }
    });
    let showLegendWindow = window.open(url.toString(), '_blank');
    showLegendWindow['data'] = this.data;
  }

  showCountPlot() {
    if (window['traces'] == null) {
      this.populatePlotlyTraces();
    }
    let url = this.router.createUrlTree(['/showCountPlot'], {
      queryParams: {
        'locusID': this.route.snapshot.queryParams['locusID'],
        'environment': this.route.snapshot.queryParams['environment'],
        'date': this.route.snapshot.queryParams['date']
      }
    });
    window.open(url.toString(), '_blank');
  }

  showAttrs() {
    let url = this.router.createUrlTree(['/showAttrs'], {
      queryParams: {
        'rowIndex': this.lastRowClicked,
        'locusID': this.route.snapshot.queryParams['locusID'],
        'environment': this.route.snapshot.queryParams['environment'],
        'date': this.route.snapshot.queryParams['date']
      }
    });
    let showAttrWindow = window.open(url.toString(), '_blank');
    showAttrWindow['data'] = this.data;
  }

  getLastRowOfTransaction() {
    let rowInd = this.lastRowClicked;
    let len = this.data['tableData'].length;

    // this.data['tableData'][rowInd][4] !== "RESET_SUBSCRIBERS" condition is added
    // because we want to stop just before reaching to the RESET_SUBSCRIBERS row.
    while (this.data && rowInd < len && this.data['tableData'][rowInd][2] === ""
      && this.data['tableData'][rowInd][4] !== "RESET_SUBSCRIBERS") {
        rowInd++;
    }
    return rowInd;
  }

  getMeetingClt() {
    let url = this.router.createUrlTree(['/meetingClt'], {
      queryParams: {
        'locusID': this.route.snapshot.queryParams['locusID'],
        'environment': this.route.snapshot.queryParams['environment'],
        'date': this.route.snapshot.queryParams['date'],
        'overview': "false"
      }
    });
    let meetingCltWindow = window.open(url.toString(), '_blank');
    meetingCltWindow['data'] = this.backendService.extractCallLevelToggles(this.data);
  }

  getDTOs() {
    this.lastRowClicked = this.getLastRowOfTransaction();
    let url = this.router.createUrlTree(['/getDTOs'], {
      queryParams: {
        'rowIndex': this.lastRowClicked, // get dtos expects the indexing to start at 1, but showAttrs expects zero indexing
        'locusID': this.route.snapshot.queryParams['locusID'],
        'environment': this.route.snapshot.queryParams['environment'],
        'date': this.route.snapshot.queryParams['date']
      }
    });
    let dtoWindow = window.open(url.toString(), '_blank');
    dtoWindow['data'] = this.data;
  }

  showBreakoutSummary(){
    let url = this.router.createUrlTree(['/getBreakoutSummary'],{
      queryParams: {
        'locusID': this.route.snapshot.queryParams['locusID'],
        'environment': this.route.snapshot.queryParams['environment'],
        'date': this.route.snapshot.queryParams['date'],
        'bid': this.breakoutId
      }
    });
    window.open(url.toString(), '_blank');
  }

  async openLocusStatic() {
    await UiUtils.openLocusStatic(this.router, this.dialog, this.backendService, this.route.snapshot.queryParams['environment'], this.locusId);
  }

  async getCallFlowDiagram() {
    await UiUtils.openCallFlowDiagram(this.dialog, this.backendService, this.route.snapshot.queryParams['environment'],
                                      this.locusId, this.startTime, this.trackingID,
                                      this.getDiagramTime('beginningTime'), this.getDiagramTime('endingTime'),
                                      this.clearCache);
  }

  getDiagramTime(which: string) {
    // returned time string must look like '2011-12-03T10:15:30Z'.
    // See https://docs.oracle.com/en/java/javase/11/docs/api/java.base/java/time/Instant.html#parse(java.lang.CharSequence)

    // offsets are plus or minus 5 seconds.  this is the same as is done in the server when times are not passed in
    if (which === 'beginningTime') {
      let startTime = DataExtractionHelpers.getTransactionTimestamp(this.data.tableData, this.data.COL_NAMES, 0);
      startTime.setSeconds(startTime.getSeconds() - 5);
      return startTime.toISOString();
    }

    if (which === 'endingTime') {
      let endTime = DataExtractionHelpers.getTransactionTimestamp(this.data.tableData, this.data.COL_NAMES, this.data.tableData.length - 1);
      endTime.setSeconds(endTime.getSeconds() + 5);
      return endTime.toISOString();
    }

    return '';
  }

  keepTransactionsChecked(newValue) {
    this.keepTransactionsCheckBox = newValue;
    this.searchFunction(this.searchString);
  }

  searchFunction(newValue) {
    this.searchString = newValue; // this is redundant since searchString is already bound to the input box, but left for code readability
    let remainingRows = [];

    let indexOfClosestItem = 0; //default to 0 so we don't scroll if no now is found

    function isRowToAdd(j: any, shouldAddTransaction: boolean) {
      if (this.searchString.startsWith("|") && this.searchString.endsWith("|")) {
        let newString = this.searchString.substring(1, this.searchString.length-1);
        if (newString.length > 0 && j.toString().toLowerCase() === newString.toLowerCase()) {
          shouldAddTransaction = true;
        }
      } else if (this.searchString.startsWith("|")) {
        let newString = this.searchString.substring(1);
        if (newString.length > 0 && j.toString().toLowerCase().indexOf(newString.toLowerCase()) == 0) {
          shouldAddTransaction = true;
        }
      } else if (this.searchString.endsWith("|")) {
        let newString = this.searchString.substring(0, this.searchString.length - 1);
        if (newString.length > 0 && j.toString().toLowerCase().indexOf(newString.toLowerCase()) != -1 &&
          j.toString().toLowerCase().indexOf(newString.toLowerCase()) == j.toString().length - newString.length) {
          shouldAddTransaction = true;
        }
      } else if (j.toString().toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1) {
        // shouldAddRowFlag = true; // a partial match was found
        shouldAddTransaction = true;
      }
      return shouldAddTransaction;
    }

    if (this.keepTransactionsCheckBox && this.searchString) {
      let tempRows = [];
      let shouldAddTransaction = false;
      for (let i of this.data['tableData']) { // i is a list of strings
        if (i[2]) { // checks if i[2] is empty string, the third index corresponds to timestamp, if a value is here, we hit a new transaction
          if (shouldAddTransaction) {
            for (let k of tempRows) {
              remainingRows.push(k);
              //while we are adding to remaining rows, check if this is the best row to scroll to
              indexOfClosestItem = this.updateClosestIndex(k, remainingRows.length - 1, indexOfClosestItem,
                remainingRows[indexOfClosestItem]);
            }
          }
          tempRows = [];
          shouldAddTransaction = false;
        }
        tempRows.push(i);
        // let shouldAddRowFlag = false;
        for (let j of i) {
          if (j) {
            this.j = j
            shouldAddTransaction = isRowToAdd.call(this, j, shouldAddTransaction);
          }
        }
      }
      if (shouldAddTransaction) { // previously were adding when coming across new transaction, need this if for final transaction
        for (let k of tempRows) {
          remainingRows.push(k);
          //while we are adding to remaining rows, check if this is the best row to scroll to
          indexOfClosestItem = this.updateClosestIndex(k, remainingRows.length - 1, indexOfClosestItem,
            remainingRows[indexOfClosestItem]);
        }
      }

    } else {
      for (let i of this.data['tableData']) {//i is a list of strings
        let shouldAddRowFlag = false;
        for (let j of i) {
          if (j) {
            this.j = j
            shouldAddRowFlag  = isRowToAdd.call(this, j, shouldAddRowFlag);
          }
        }
        if (shouldAddRowFlag || !this.searchString) {
          remainingRows.push(i);
          //while we are adding to remaining rows, check if this is the best row to scroll to
          indexOfClosestItem = this.updateClosestIndex(i, remainingRows.length - 1, indexOfClosestItem,
            remainingRows[indexOfClosestItem]);
        }
      }
    }

    let colNames = this.createIndexToTitleMap(this.data['COL_NAMES']);
    this.journalRowsGridApi.setGridOption('rowData', this.createAgGridDict(remainingRows, colNames));
    this.findScrollTimes(remainingRows);
    // clear row click history upon search
    this.rowClickHistory = [];

    if (remainingRows.length > this.journalRowViewportSize && indexOfClosestItem > 0) {
      this.journalRowsGridApi.ensureIndexVisible(indexOfClosestItem, "top")
    }
  }

  updateClosestIndex(journalGridRow, journalGridRowIndex, closestIndex, closestIndexRow): number {
    if (this.calculateRowNumberDifference(journalGridRow, this.lastRowClicked)
      < this.calculateRowNumberDifference(closestIndexRow, this.lastRowClicked)) {
      return journalGridRowIndex;
    }
    return closestIndex;
  }

  calculateRowNumberDifference(journalGridRow, queryRowNumber): number {
    if (journalGridRow) {
      if (journalGridRow[0]) {
        return Math.abs(journalGridRow[0] - queryRowNumber);
      }
    }
    return Number.MAX_SAFE_INTEGER;
  }

  // Uses the query string this.scrollTimeString and agGrid's ensureIndexVisible() to scroll to the next time relative to the input
  scrollFunction() {
    if (this.bottomRowIndex !== this.DEFAULT_BOTTOM_ROW_INDEX) {
      if (!this.scrollTimeString.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}T\d\d/g)) {
        this.showScrollTimeErrorMessage = true;
      } else {
        this.showScrollTimeErrorMessage = false;
        this.scrollToTimestamp(this.scrollTimeString);
      }
    }
  }

  scrollToTimestamp(targetTimestamp: string) {
    const index = this.findScrollTimesTargetIndex(targetTimestamp, "timestamp");
    if (this.scrollTimesArray[index]) {
      // Native AgGrid scroll method
      this.journalRowsGridApi.ensureIndexVisible(this.scrollTimesArray[index]["rowIndex"], 'top');
    } else {
      console.error('scrollToTimestamp(): alert: this.scrollTimesArray[index] is invalid');
    }
  }

  getUserIndexForJournalRowIndex(rowIndex) {
    let journalRowUserIndex = DataExtractionHelpers.findColumnIndexFromName(this.data['COL_NAMES'], 'User');
    let journalRowUser = this.data['tableData'][rowIndex][journalRowUserIndex];
    let userGridIndex = -1;
    this.userRowsGridApi.forEachNode(node=> (node.data['User'] === journalRowUser) ? userGridIndex = node.rowIndex : -1);
    return userGridIndex;
  }

  // Keep the scroll time input updated
  timeChangeFunction(input: string) {
    this.scrollTimeString = input;
  }

  scrollToTop() {
    if (this.bottomRowIndex !== this.DEFAULT_BOTTOM_ROW_INDEX) {
      this.journalRowsGridApi.ensureIndexVisible(0, 'top');
    }
  }

  scrollToBottom() {
    if (this.bottomRowIndex !== this.DEFAULT_BOTTOM_ROW_INDEX) {
      this.journalRowsGridApi.ensureIndexVisible(this.bottomRowIndex, 'top');
    }
  }

  //Currently only works with all displayed rows. If needed in the future after a filter is applied to the rows, use
  //this.journalRowsGridApi.getRowNode(correctScrollIndex)["data"]["Row"] to get the row node's number
  findCurrentScrollIndex(): number {
    //use short circuit evaluation to return early if missing data
    if (!this.journalRowsGridOptions || !this.journalRowsGridOptions["api"]) {
      return 0;
    }
    let correctScrollIndex: any;
    let visibleRowBuffer = 10; //ag grid defaults to a 10 row buffer

    let topVisibleRowIndex = this.journalRowsGridApi.getFirstDisplayedRow();
    let bottomVisibleRowIndex = this.journalRowsGridApi.getLastDisplayedRow();

    if (topVisibleRowIndex == 0) { //if we're at the top
      //calculate the difference between how many rows can be visible (including buffer), and how many are actually visible
      //we need this in case we are at top of the grid, since then the buffer is collapsed and less than 10
      let visibleRowDifference = this.journalRowViewportSize + 2 * visibleRowBuffer - (bottomVisibleRowIndex - topVisibleRowIndex);
      correctScrollIndex = topVisibleRowIndex + visibleRowBuffer - visibleRowDifference;
    } else { // if we're not at the top, the correct index is the topVisible plus the buffer
      correctScrollIndex = topVisibleRowIndex + visibleRowBuffer;
    }

    //ensure index is within the bounds of the grid
    correctScrollIndex = Math.min(Math.max(0, correctScrollIndex), this.bottomRowIndex);
    return correctScrollIndex;

    // Visual representation of the ag grid
    // +-----------+
    // |    10     |   ag grid default top buffer
    // +-----------+
    // |    20     |   viewport visible to users
    // |           |
    // +-----------+
    // |    10     |   ag grid default bottom buffer
    // +-----------+
    // note that the top and bottom buffers may be smaller than 10 if the viewport is near the very top or bottom of the journal
  }

  // key Navigation Functionality
  keyNavigation(e) {
    if (e.event && this.journalRowsGridApi.getSelectedNodes().length === 1) {
      const keyPressed = (e.event as KeyboardEvent).key;
      // The command key (Mac)
      const metakey = (e.event as KeyboardEvent).metaKey;
      // The control key
      const ctrlkey = (e.event as KeyboardEvent).ctrlKey;

      if (keyPressed === this.KEY_DOWN && metakey || keyPressed === 'n' && this.keepTransactionsCheckBox) {
        this.searchChange();
        this.selectNewRow(this.getTransactionStart('next'));

      } else if (keyPressed === this.KEY_UP && metakey || keyPressed === 'N' && this.keepTransactionsCheckBox) {
        this.searchChange();
        this.selectNewRow(this.getTransactionStart('prev'));

      } else if (keyPressed === 'j') {
        let rowIndex = this.journalRowsGridApi.getSelectedNodes()[0].rowIndex;
        let rowCount = this.journalRowsGridApi.getDisplayedRowCount();
        let nextRow = (rowIndex + 1 < rowCount) ? this.journalRowsGridApi.getDisplayedRowAtIndex(rowIndex + 1).id : 0;
        this.selectNewRow(nextRow);

      } else if (keyPressed === 'k') {
        let rowIndex = this.journalRowsGridApi.getSelectedNodes()[0].rowIndex;
        let lastRow = this.journalRowsGridApi.getDisplayedRowCount() - 1;
        let prevRow = (rowIndex - 1 >= 0) ? this.journalRowsGridApi.getDisplayedRowAtIndex(rowIndex - 1).id : lastRow;
        this.selectNewRow(prevRow);

      } else if (keyPressed === 'b' ) {
        this.selectNewRow(this.getPrevRow());

      } else if (keyPressed === 'c' && (ctrlkey || metakey) ) {
        let rowNode = this.journalRowsGridApi.getSelectedNodes()[0];
        // for an empty Timestamp cell, we'll copy the entire row
        if (this.timestampClicked && rowNode.data[JournalConstants.COLUMN_TIMESTAMP]) {
          copyToClipBoard(rowNode.data[JournalConstants.COLUMN_TIMESTAMP]);
        } else {
          let rowInfo = JSON.stringify(rowNode.data);
          // adds spaces after each comma and colon to make the string easier to read
          rowInfo = rowInfo.replace(/,/g, ', ').replace(/":"/g, '": "');
          copyToClipBoard(rowInfo);
        }
      } else if (keyPressed === 'Enter') {
        // upon pressing enter, a selected row's DTOs are shown
        this.getDTOs();
      }
    }
  }

  // filters a map of the first rows of each transaction done by a user
  filterByUserTransactionMap() {
    // starts at the first row
    let currentUser = this.journalRowsGridApi.getDisplayedRowAtIndex(0).data.User;
    this.userTransactionMap.set(currentUser, [0]);
    this.journalRowsGridApi.forEachNode((rowNode, index) => {
      let rowUser = rowNode.data.User;
      if (!this.userTransactionMap.has(rowUser)) {
        this.userTransactionMap.set(rowUser, []);
      }

      if (currentUser != rowUser) {
        let id = parseInt(this.journalRowsGridApi.getDisplayedRowAtIndex(index).id, 10);
        this.userTransactionMap.get(rowUser).push(id);
        currentUser = rowUser;
      }

    });
  }

  // search for a INCLUDE control in a UPDATE_CONTROL_STATES row. If the breakout control exists
  // then we make the breakout summary button appear
  postProcessForBreakoutSummary() {
    if (this.data) {
      const deviceEventIndex = DataExtractionHelpers.findColumnIndexFromName(this.data['COL_NAMES'], 'Device Event');

      for (let rowIndex = 0; rowIndex < this.data['tableData'].length; rowIndex++) {

        const deviceEvent = this.data['tableData'][rowIndex][deviceEventIndex];

        if (deviceEvent !== 'UPDATE_CONTROL_STATES') continue;

        const controls = JSON.parse(this.data['response']['rowList'][rowIndex]['attrs'])['attrs']['controlStates'];
        const breakoutControl = controls.find(control => (control['controlType'] === 'INCLUDE' && 'breakout' in control['includeControlStatesInfo'] && control['includeControlStatesInfo']['breakout']['type'] === 'breakout'));

        if (breakoutControl == null) continue;

        let breakoutUrl = breakoutControl['includeControlStatesInfo']['breakout']['data']['url'];
        this.breakoutHidden = '';
        this.breakoutId = breakoutUrl.substring(breakoutUrl.lastIndexOf('/') + 1);

        break;
      }
    }
  }

  // gets the next or previous transaction, returning the rowID
  // the parameter 'direction' only takes in the strings 'prev' and 'next' to find a transaction
  getTransactionStart(direction) {
    let rowID = this.journalRowsGridApi.getSelectedNodes()[0].id;
    let selectedRowUser = this.journalRowsGridApi.getDisplayedRowAtIndex(parseInt(rowID)).data.User;
    // checks to make sure that the user is not an empty string
    // if empty, return selected row
    if (!selectedRowUser) {
      console.warn('There is no user for the selected row.');
      return rowID;
    }

    let userRowIndices = this.userTransactionMap.get(selectedRowUser);

    if (userRowIndices === undefined || userRowIndices.length == 0) {
      console.warn('No rows have been found for the selected user.');
      return rowID;
    }

    if (direction === 'prev') {
      let startIndex = this.getClosestIndex(userRowIndices, rowID, 'below');
      // rowID will only update if there are transactions preceding the currently selected row
      // rowID will only update if there's a user for the current row.
      if (startIndex > 0) {
        startIndex--;
        rowID = userRowIndices[startIndex];
      }
    } else if (direction === 'next') {
      let len = userRowIndices.length;
      let startIndex = this.getClosestIndex(userRowIndices, rowID, 'below');
      // rowIndex will only update if there are indices to proceed to and if user is not empty
      if (startIndex < len - 1) {
        startIndex++;
        rowID = userRowIndices[startIndex];
      }
    }

    return rowID;
  }

  // get the element from an array closest to the input without considering elements after or before the given direction
  // the direction parameter only accepts 'above' or 'below' to search for indices.
  getClosestIndex(arr, input, direction) {
    if (arr === undefined || arr.length == 0) {
      console.warn('The input array is not valid.');
      return 0;
    }

    if (direction === 'below') {
      // filters out numbers less than or equal to the input within arr
      let filter = function (nums) {
        return nums <= input;
      };

      let element = Math.max.apply(null, arr.filter(filter));
      // if index is not found, set the index to the first row instead
      return arr.indexOf(element) != -1 ? arr.indexOf(element) : 0;
    } else if (direction === 'above') {
      // filters out numbers greater than or equal to the input within arr
      let filter = function (nums) {
        return nums >= input;
      };

      let element = Math.max.apply(null, arr.filter(filter));
      // if index is not found, set the index to the first row instead
      return arr.indexOf(element) != -1 ? arr.indexOf(element) : 0;
    } else {
      console.warn('Incorrect direction provided to getClosestIndex().');
    }
    return 0;
  }

  // returns the row id of the row selected before the current.
  getPrevRow() {
    let currRowID = this.journalRowsGridApi.getSelectedNodes()[0].id;
    // if there are at least two elements in an array, remove the first
    if (this.rowClickHistory.length > 1) {
      this.rowClickHistory.shift();
    } else {
      console.warn('There are no previous rows to go to.');
    }

    // the function can only be entered if there is at least one row clicked, but this is a precautionary catch
    // if there is nothing in the array, then set the last clicked element to the first row
    let lastClickedElement = this.rowClickHistory[0] ? this.rowClickHistory[0] : currRowID;
    return this.journalRowsGridApi.getRowNode(lastClickedElement).id;
  }

  // selects the new row based on provided index and puts the row in view and focus
  selectNewRow(rowClicked) {
    let rowNode = this.journalRowsGridApi.getRowNode(rowClicked);
    this.lastRowClicked = rowNode.data.Row;
    rowNode.setSelected(true, true);
    //TOVISHAL: this.journalRowsGridApi.setFocusedCell(rowClicked);
    this.journalRowsGridApi.ensureIndexVisible(parseInt(rowClicked, 10), 'middle'); // rowClicked is a string
  }

  // updates userTransactionMap if the string input is changed
  searchChange() {
    if (!(this.priorSearchString === this.searchString)) {
      this.userTransactionMap.clear();
      this.filterByUserTransactionMap();
    }
    this.priorSearchString = this.searchString;
  }

  getKeyBindingText() {
    return `KEYBOARD SHORTCUTS
    CMD + UP : Select the first row of the previous transaction for the user.
    CMD + DOWN : Select the first row of the next transaction for the user.
    (CTRL / CMD) + C : Copy the row information of the currently selected row to the clipboard in JSON format.
    ENTER: Get the DTOs for the currently selected row.

    VI KEY BINDINGS:
    k : Select the previous row.
    j : Select the next row.
    b : Select the row that was selected before the current row.
    N : Select the first row of the previous transaction for the user.
    n : Select the first row of the next transaction for the user.

    CLICK EVENTS:
    Double Click: The attributes of the clicked row will be brought up.`;
  }

  /**
   * Event handler for when the Kibana menu drop down as an option selected
   *
   * @param option - The menu option that was selected
   */
  async onKibanaClick(option) {
    let rows = this.journalRowsGridApi.getSelectedNodes().length;

    // Simply fall through if the user selected OPEN_ROW_SELECTIONS but no rows are selected
    if (option == KibanaConstants.OPEN_GENERAL_MEETING) {
      await this.openKibanaLogsForMeeting();
    } else if (option == KibanaConstants.OPEN_ROW_SELECTIONS && rows == 1) {
      await this.openKibanaLogsForSelectedRow();
    } else if (option == KibanaConstants.OPEN_ROW_SELECTIONS && rows > 1) {
      await this.openKibanaLogsForMultiselect();
    }
  }

  /**
   * Handles the request to open a Kibana search for the meeting as a whole using only the
   * general meeting parameters
   */
  async openKibanaLogsForMeeting() {
    let startTime = DataExtractionHelpers.getTransactionTimestamp(this.data.tableData, this.data.COL_NAMES, 0);
    startTime.setMinutes(startTime.getMinutes() - KibanaConstants.PAST_OFFSET);

    let endTime = DataExtractionHelpers.getTransactionTimestamp(this.data.tableData, this.data.COL_NAMES, this.data.tableData.length - 1);
    let endTimeString: string;

    if (!this.isLiveMeeting) {
      endTime.setMinutes(endTime.getMinutes() + KibanaConstants.FUTURE_OFFSET);
      endTimeString = endTime.toISOString();
    } else {
      endTimeString = 'now';
    }

    let kibanaURL = KibanaUtility.constructKibanaSearchURL(
      KibanaUtility.getKibanaEnvironment(this.env),
      startTime.toISOString(),
      endTimeString,
      ['message', 'tags'],
      [['tags', ['locus'], false], ['fields.LOCUS_ID', [this.locusId], false]],
      ""
    );

    window.open(kibanaURL, '_blank');
  }

  /**
   * Handles the request to open a Kibana search if only a single row has been selected by the user.
   */
  async openKibanaLogsForSelectedRow() {
    let selectedRow = Number(this.journalRowsGridApi.getSelectedNodes()[0].id);
    let transactionRange = DataExtractionHelpers.getTransactionRange(this.data.tableData, this.data.COL_NAMES, selectedRow);
    let timestamp = DataExtractionHelpers.getTransactionTimestamp(this.data.tableData, this.data.COL_NAMES, selectedRow);

    let startTime = new Date(timestamp);
    startTime.setMinutes(startTime.getMinutes() - KibanaConstants.PAST_OFFSET);
    timestamp.setMinutes(timestamp.getMinutes() + KibanaConstants.FUTURE_OFFSET);

    // Pull out which kibana environment to open, as well as format start and end times
    // The filters and search bar will be used as a default if no attribute is found
    let kibanaEnv: string = KibanaUtility.getKibanaEnvironment(this.env);
    let startTimeString: string = startTime.toISOString();
    let endTimeString: string = timestamp.toISOString();
    let resultColumns: string[] = ['message', 'tags'];
    let filters: [string, string[], boolean][] = [['tags', ['locus'], false], ['fields.LOCUS_ID', [this.locusId], false]];
    let searchBar: string = "";

    // Define which attributes we will search and in what order, then pull info out of transaction
    let rowAttributes = ['trackingId', 'correlationId', 'confluenceId'];

    for (let i = 0; i < rowAttributes.length; i++) {
      let filterValue = DataExtractionHelpers.searchJSONRowRangeForAttribute(this.attrsList, transactionRange[0], transactionRange[1], rowAttributes[i]);

      if (filterValue != null) {
        // Depending on which attribute we are searching on, we will want different filters or search bar string
        if (rowAttributes[i] === 'trackingId') {
          filters = [['tags', ['locus'], false], ['fields.WEBEX_TRACKINGID', [filterValue], false]];
        } else if (rowAttributes[i] === 'correlationId') {
          searchBar = filterValue;
        } else if (rowAttributes[i] === 'confluenceId') {
          filters = [];
          searchBar = filterValue;
        }

        break;
      }
    }

    let kibanaURL = KibanaUtility.constructKibanaSearchURL(kibanaEnv, startTimeString, endTimeString, resultColumns, filters, searchBar);
    window.open(kibanaURL, '_blank');
  }

  /**
   * Handles the request to open a Kibana search if multiple rows have been selected by the user.
   */
  async openKibanaLogsForMultiselect() {
    // Note, this assumes the selected nodes are ordered by increasing row number
    let selectedRows = this.journalRowsGridApi.getSelectedNodes();
    let firstSelectedRow = Number(selectedRows[0].id);
    let lastSelectedRow = Number(selectedRows[selectedRows.length - 1].id);

    let firstTransactionTimestamp = DataExtractionHelpers.getTransactionTimestamp(this.data.tableData, this.data.COL_NAMES, firstSelectedRow);
    let secondTransactionTimestamp = DataExtractionHelpers.getTransactionTimestamp(this.data.tableData, this.data.COL_NAMES, lastSelectedRow);
    firstTransactionTimestamp.setMinutes(firstTransactionTimestamp.getMinutes() - KibanaConstants.PAST_OFFSET);
    secondTransactionTimestamp.setMinutes(secondTransactionTimestamp.getMinutes() + KibanaConstants.FUTURE_OFFSET);

    let kibanaURL = KibanaUtility.constructKibanaSearchURL(
      KibanaUtility.getKibanaEnvironment(this.env),
      firstTransactionTimestamp.toISOString(),
      secondTransactionTimestamp.toISOString(),
      ['message', 'tags'],
      [['tags', ['locus'], false], ['fields.LOCUS_ID', [this.locusId], false]],
      ""
    );

    window.open(kibanaURL, '_blank');
  }
}
