import { Injectable, ViewChild } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication/authentication.service';
import { AuthInfoService } from '../_services/authInfo.service';
import { Observable } from 'rxjs';
import { UserService } from '../users/user.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';
import { EnvironmentService } from '../_services/environment.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private isLoggedIn: boolean;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authInfoService: AuthInfoService,
    private dialog: MatDialog, private userService: UserService, private environment: EnvironmentService
  ) {
    this.authenticationService.isLoggedIn$.subscribe((loginState) => {
      this.isLoggedIn = loginState;
    });
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isLoggedIn) {
      let scopes = await this.userService.getLoggedInUserInfo().subscribe((data) => {
        if (data['entitlements'].indexOf('journal-tool') <= -1) {
          const dialogRef = this.dialog.open(DialogBoxComponent, {
            width: '350px',
            data: { message: "You do not have the required entitlement: 'journal-tool' attached to your profile" }
          });
          window.localStorage.clear();
          dialogRef.afterClosed().subscribe(res => {
            window.open(`https://${this.environment.getBrokerUrl()}/idb/oauth2/v1/logout`, '_self');
            return false;
          });
        }
      });
      return true;
    }

    this.router.navigate(['/login'], { queryParams: route.queryParams });
    return false;
  }
}
