import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {JournalConstants} from "../_constants/journal-constants";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private currentTheme: BehaviorSubject<string>;
  private currentAgGridTheme: BehaviorSubject<string>;
  static storageKey: string = 'journal-tool-theme-name';

  constructor() {
    this.currentTheme = new BehaviorSubject<string>(this.getStoredThemeName());
    this.currentAgGridTheme = new BehaviorSubject<string>(this.mapAgGridTheme(this.getStoredThemeName()));
  }

  setTheme(themeName: string) {
    this.currentTheme.next(themeName);
    this.currentAgGridTheme.next(this.mapAgGridTheme(themeName));
    this.setStoredTheme(themeName);
  }

  getCurrentTheme() {
    return this.getStoredThemeName();
  }

  getTheme(): Observable<string> {
    return this.currentTheme.asObservable();
  }

  getAgGridTheme(): Observable<string> {
    return this.currentAgGridTheme.asObservable();
  }

  mapAgGridTheme(currentTheme: string) {
    console.log(currentTheme);
    if (currentTheme === JournalConstants.JT_LIGHT_THEME) {
      console.log('returning ag light theme')
      return JournalConstants.AG_LIGHT_THEME;
    } else {
      console.log('returning ag dark theme')
      return JournalConstants.AG_DARK_THEME;
    }
  }

  private setStoredTheme(themeName: string) {
    try {
      window.localStorage[ThemeService.storageKey] = themeName;
    } catch {
      console.log("error storing theme locally");
    }
  }

  private getStoredThemeName(): string | null {
    try {
      return window.localStorage[ThemeService.storageKey] || JournalConstants.JT_LIGHT_THEME;
    } catch {
      console.error("error fetching theme, returning default");
      return JournalConstants.JT_LIGHT_THEME;
    }
  }

  private static clearStoredTheme() {
    try {
      window.localStorage.removeItem(ThemeService.storageKey);
    } catch { }
  }
}
